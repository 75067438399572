.advantages {
    &__head {
        margin-bottom: 80px;
        text-align: center; }
    &__title {
        margin-bottom: 20px; }
    &__info {
        max-width: 455px;
        margin: 0 auto;
        color: $neutrals3;
        @include d {
            max-width: 100%;
            color: $neutrals4; }
        @include dark {
            color: $neutrals5; } }
    &__list {
        display: flex;
        margin: 0 -12px;
        @include t {
            margin: 0 -40px;
            overflow: auto;
            overflow-x: auto;
            -ms-overflow-style: none;
            scrollbar-width: none;
            -webkit-overflow-scrolling: touch;
            &::-webkit-scrollbar {
                display: none; }
            &:before,
            &:after {
                content: "";
                flex-shrink: 0;
                width: 40px;
                height: 1px; } } }
    &__item {
        flex: 0 0 calc(33.333% - 24px);
        width: calc(33.333% - 24px);
        margin: 0 12px;
        padding: 0 32px;
        text-align: center;
        @include t {
            flex: 0 0 280px;
            margin: 0;
            padding: 0; }
        @include m {
            flex: 0 0 260px; }
        &:not(:last-child) {
            @include t {
                margin-right: 32px; }
            @include m {
                margin-right: 8px; } } }
    &__preview {
        height: 204px;
        margin-bottom: 36px;
        img {
            max-height: 100%; } }
    &__subtitle {
        @include body-2; } }

