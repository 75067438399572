.subscription {
    position: relative;
    &__input {
        width: 100%;
        height: 48px;
        padding: 0 48px 0 14px;
        border-radius: 24px;
        background: none;
        border: 2px solid $neutrals6;
        @include poppins;
        @include caption-1;
        color: $neutrals2;
        transition: border-color .2s;
        &:focus {
            border-color: $neutrals4; }
        @include placeholder {
            color: $neutrals4; }
        @include dark {
            border-color: $neutrals3;
            color: $neutrals8;
            &:focus {
                border-color: $neutrals4; } } }

    &__btn {
        position: absolute;
        top: 8px;
        right: 8px;
        bottom: 8px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $red;
        transition: background .2s;
        .icon {
            width: 14px;
            height: 14px;
            fill: $neutrals8; }
        &:hover {
            background: darken($red, 10); } } }
