html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5,
h6, p, blockquote, pre, a, abbr, acronym, address, big,
cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small,
strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt,
dd, ol, ul, li, fieldset, form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td, article, aside, canvas, details,
embed, figure, figcaption, footer, header, hgroup, menu, nav,
output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

img, fieldset, a img {
  border: none; }

input[type="text"],
input[type="email"],
input[type="tel"],
textarea {
  -webkit-appearance: none; }

input[type="submit"],
button {
  cursor: pointer; }
  input[type="submit"]::-moz-focus-inner,
  button::-moz-focus-inner {
    padding: 0;
    border: 0; }

textarea {
  overflow: auto; }

input, button {
  margin: 0;
  padding: 0;
  border: 0; }

div, input, textarea, select, button,
h1, h2, h3, h4, h5, h6, a, span, a:focus {
  outline: none; }

ul, ol {
  list-style-type: none; }

table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: url("../fonts/SFProDisplay-Bold.woff") format("woff"), url("../fonts/fonts/SFProDisplay-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.nice-select {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 5px;
  border: solid 1px #e8e8e8;
  box-sizing: border-box;
  clear: both;
  cursor: pointer;
  display: block;
  float: left;
  font-family: inherit;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  line-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 30px;
  position: relative;
  text-align: left !important;
  transition: all 0.2s ease-in-out;
  user-select: none;
  white-space: nowrap;
  width: auto; }
  .nice-select:hover {
    border-color: #dbdbdb; }
  .nice-select:active, .nice-select.open, .nice-select:focus {
    border-color: #999; }
  .nice-select:after {
    border-bottom: 2px solid #999;
    border-right: 2px solid #999;
    content: '';
    display: block;
    height: 5px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 12px;
    top: 50%;
    transform-origin: 66% 66%;
    transform: rotate(45deg);
    transition: all 0.15s ease-in-out;
    width: 5px; }
  .nice-select.open:after {
    transform: rotate(-135deg); }
  .nice-select.open .list {
    opacity: 1;
    pointer-events: auto;
    transform: scale(1) translateY(0); }
  .nice-select.disabled {
    border-color: #ededed;
    color: #999;
    pointer-events: none; }
    .nice-select.disabled:after {
      border-color: #cccccc; }
  .nice-select.wide {
    width: 100%; }
    .nice-select.wide .list {
      left: 0 !important;
      right: 0 !important; }
  .nice-select.right {
    float: right; }
    .nice-select.right .list {
      left: auto;
      right: 0; }
  .nice-select.small {
    font-size: 12px;
    height: 36px;
    line-height: 34px; }
    .nice-select.small:after {
      height: 4px;
      width: 4px; }
    .nice-select.small .option {
      line-height: 34px;
      min-height: 34px; }
  .nice-select .list {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 0 1px rgba(68, 68, 68, 0.11);
    box-sizing: border-box;
    margin-top: 4px;
    opacity: 0;
    overflow: hidden;
    padding: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    transform-origin: 50% 0;
    transform: scale(0.75) translateY(-21px);
    transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
    z-index: 9; }
    .nice-select .list:hover .option:not(:hover) {
      background-color: transparent !important; }
  .nice-select .option {
    cursor: pointer;
    font-weight: 400;
    line-height: 40px;
    list-style: none;
    min-height: 40px;
    outline: none;
    padding-left: 18px;
    padding-right: 29px;
    text-align: left;
    transition: all 0.2s; }
    .nice-select .option:hover, .nice-select .option.focus, .nice-select .option.selected.focus {
      background-color: #f6f6f6; }
    .nice-select .option.selected {
      font-weight: bold; }
    .nice-select .option.disabled {
      background-color: transparent;
      color: #999;
      cursor: default; }

.no-csspointerevents .nice-select .list {
  display: none; }

.no-csspointerevents .nice-select.open .list {
  display: block; }

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }

.mfp-preloader a {
  color: #CCC; }

.mfp-preloader a:hover {
  color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1; }

.mfp-close:active, .mfp-close.nice-select.open {
  top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap; }

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }

.mfp-arrow:active, .mfp-arrow.nice-select.open {
  margin-top: -54px; }

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1; }

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent; }

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px; }

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px; }

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px; }

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px; }

.mfp-iframe-holder .mfp-close {
  top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444; }

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px; }

.mfp-figure figure {
  margin: 0; }

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

[data-aos][data-aos][data-aos-duration="50"], body[data-aos-duration="50"] [data-aos] {
  transition-duration: 50ms; }

[data-aos][data-aos][data-aos-delay="50"], body[data-aos-delay="50"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="50"].aos-animate, body[data-aos-delay="50"] [data-aos].aos-animate {
  transition-delay: 50ms; }

[data-aos][data-aos][data-aos-duration="100"], body[data-aos-duration="100"] [data-aos] {
  transition-duration: .1s; }

[data-aos][data-aos][data-aos-delay="100"], body[data-aos-delay="100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="100"].aos-animate, body[data-aos-delay="100"] [data-aos].aos-animate {
  transition-delay: .1s; }

[data-aos][data-aos][data-aos-duration="150"], body[data-aos-duration="150"] [data-aos] {
  transition-duration: .15s; }

[data-aos][data-aos][data-aos-delay="150"], body[data-aos-delay="150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="150"].aos-animate, body[data-aos-delay="150"] [data-aos].aos-animate {
  transition-delay: .15s; }

[data-aos][data-aos][data-aos-duration="200"], body[data-aos-duration="200"] [data-aos] {
  transition-duration: .2s; }

[data-aos][data-aos][data-aos-delay="200"], body[data-aos-delay="200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="200"].aos-animate, body[data-aos-delay="200"] [data-aos].aos-animate {
  transition-delay: .2s; }

[data-aos][data-aos][data-aos-duration="250"], body[data-aos-duration="250"] [data-aos] {
  transition-duration: .25s; }

[data-aos][data-aos][data-aos-delay="250"], body[data-aos-delay="250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="250"].aos-animate, body[data-aos-delay="250"] [data-aos].aos-animate {
  transition-delay: .25s; }

[data-aos][data-aos][data-aos-duration="300"], body[data-aos-duration="300"] [data-aos] {
  transition-duration: .3s; }

[data-aos][data-aos][data-aos-delay="300"], body[data-aos-delay="300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="300"].aos-animate, body[data-aos-delay="300"] [data-aos].aos-animate {
  transition-delay: .3s; }

[data-aos][data-aos][data-aos-duration="350"], body[data-aos-duration="350"] [data-aos] {
  transition-duration: .35s; }

[data-aos][data-aos][data-aos-delay="350"], body[data-aos-delay="350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="350"].aos-animate, body[data-aos-delay="350"] [data-aos].aos-animate {
  transition-delay: .35s; }

[data-aos][data-aos][data-aos-duration="400"], body[data-aos-duration="400"] [data-aos] {
  transition-duration: .4s; }

[data-aos][data-aos][data-aos-delay="400"], body[data-aos-delay="400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="400"].aos-animate, body[data-aos-delay="400"] [data-aos].aos-animate {
  transition-delay: .4s; }

[data-aos][data-aos][data-aos-duration="450"], body[data-aos-duration="450"] [data-aos] {
  transition-duration: .45s; }

[data-aos][data-aos][data-aos-delay="450"], body[data-aos-delay="450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="450"].aos-animate, body[data-aos-delay="450"] [data-aos].aos-animate {
  transition-delay: .45s; }

[data-aos][data-aos][data-aos-duration="500"], body[data-aos-duration="500"] [data-aos] {
  transition-duration: .5s; }

[data-aos][data-aos][data-aos-delay="500"], body[data-aos-delay="500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="500"].aos-animate, body[data-aos-delay="500"] [data-aos].aos-animate {
  transition-delay: .5s; }

[data-aos][data-aos][data-aos-duration="550"], body[data-aos-duration="550"] [data-aos] {
  transition-duration: .55s; }

[data-aos][data-aos][data-aos-delay="550"], body[data-aos-delay="550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="550"].aos-animate, body[data-aos-delay="550"] [data-aos].aos-animate {
  transition-delay: .55s; }

[data-aos][data-aos][data-aos-duration="600"], body[data-aos-duration="600"] [data-aos] {
  transition-duration: .6s; }

[data-aos][data-aos][data-aos-delay="600"], body[data-aos-delay="600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="600"].aos-animate, body[data-aos-delay="600"] [data-aos].aos-animate {
  transition-delay: .6s; }

[data-aos][data-aos][data-aos-duration="650"], body[data-aos-duration="650"] [data-aos] {
  transition-duration: .65s; }

[data-aos][data-aos][data-aos-delay="650"], body[data-aos-delay="650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="650"].aos-animate, body[data-aos-delay="650"] [data-aos].aos-animate {
  transition-delay: .65s; }

[data-aos][data-aos][data-aos-duration="700"], body[data-aos-duration="700"] [data-aos] {
  transition-duration: .7s; }

[data-aos][data-aos][data-aos-delay="700"], body[data-aos-delay="700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="700"].aos-animate, body[data-aos-delay="700"] [data-aos].aos-animate {
  transition-delay: .7s; }

[data-aos][data-aos][data-aos-duration="750"], body[data-aos-duration="750"] [data-aos] {
  transition-duration: .75s; }

[data-aos][data-aos][data-aos-delay="750"], body[data-aos-delay="750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="750"].aos-animate, body[data-aos-delay="750"] [data-aos].aos-animate {
  transition-delay: .75s; }

[data-aos][data-aos][data-aos-duration="800"], body[data-aos-duration="800"] [data-aos] {
  transition-duration: .8s; }

[data-aos][data-aos][data-aos-delay="800"], body[data-aos-delay="800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="800"].aos-animate, body[data-aos-delay="800"] [data-aos].aos-animate {
  transition-delay: .8s; }

[data-aos][data-aos][data-aos-duration="850"], body[data-aos-duration="850"] [data-aos] {
  transition-duration: .85s; }

[data-aos][data-aos][data-aos-delay="850"], body[data-aos-delay="850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="850"].aos-animate, body[data-aos-delay="850"] [data-aos].aos-animate {
  transition-delay: .85s; }

[data-aos][data-aos][data-aos-duration="900"], body[data-aos-duration="900"] [data-aos] {
  transition-duration: .9s; }

[data-aos][data-aos][data-aos-delay="900"], body[data-aos-delay="900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="900"].aos-animate, body[data-aos-delay="900"] [data-aos].aos-animate {
  transition-delay: .9s; }

[data-aos][data-aos][data-aos-duration="950"], body[data-aos-duration="950"] [data-aos] {
  transition-duration: .95s; }

[data-aos][data-aos][data-aos-delay="950"], body[data-aos-delay="950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="950"].aos-animate, body[data-aos-delay="950"] [data-aos].aos-animate {
  transition-delay: .95s; }

[data-aos][data-aos][data-aos-duration="1000"], body[data-aos-duration="1000"] [data-aos] {
  transition-duration: 1s; }

[data-aos][data-aos][data-aos-delay="1000"], body[data-aos-delay="1000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1000"].aos-animate, body[data-aos-delay="1000"] [data-aos].aos-animate {
  transition-delay: 1s; }

[data-aos][data-aos][data-aos-duration="1050"], body[data-aos-duration="1050"] [data-aos] {
  transition-duration: 1.05s; }

[data-aos][data-aos][data-aos-delay="1050"], body[data-aos-delay="1050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1050"].aos-animate, body[data-aos-delay="1050"] [data-aos].aos-animate {
  transition-delay: 1.05s; }

[data-aos][data-aos][data-aos-duration="1100"], body[data-aos-duration="1100"] [data-aos] {
  transition-duration: 1.1s; }

[data-aos][data-aos][data-aos-delay="1100"], body[data-aos-delay="1100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1100"].aos-animate, body[data-aos-delay="1100"] [data-aos].aos-animate {
  transition-delay: 1.1s; }

[data-aos][data-aos][data-aos-duration="1150"], body[data-aos-duration="1150"] [data-aos] {
  transition-duration: 1.15s; }

[data-aos][data-aos][data-aos-delay="1150"], body[data-aos-delay="1150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1150"].aos-animate, body[data-aos-delay="1150"] [data-aos].aos-animate {
  transition-delay: 1.15s; }

[data-aos][data-aos][data-aos-duration="1200"], body[data-aos-duration="1200"] [data-aos] {
  transition-duration: 1.2s; }

[data-aos][data-aos][data-aos-delay="1200"], body[data-aos-delay="1200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1200"].aos-animate, body[data-aos-delay="1200"] [data-aos].aos-animate {
  transition-delay: 1.2s; }

[data-aos][data-aos][data-aos-duration="1250"], body[data-aos-duration="1250"] [data-aos] {
  transition-duration: 1.25s; }

[data-aos][data-aos][data-aos-delay="1250"], body[data-aos-delay="1250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1250"].aos-animate, body[data-aos-delay="1250"] [data-aos].aos-animate {
  transition-delay: 1.25s; }

[data-aos][data-aos][data-aos-duration="1300"], body[data-aos-duration="1300"] [data-aos] {
  transition-duration: 1.3s; }

[data-aos][data-aos][data-aos-delay="1300"], body[data-aos-delay="1300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1300"].aos-animate, body[data-aos-delay="1300"] [data-aos].aos-animate {
  transition-delay: 1.3s; }

[data-aos][data-aos][data-aos-duration="1350"], body[data-aos-duration="1350"] [data-aos] {
  transition-duration: 1.35s; }

[data-aos][data-aos][data-aos-delay="1350"], body[data-aos-delay="1350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1350"].aos-animate, body[data-aos-delay="1350"] [data-aos].aos-animate {
  transition-delay: 1.35s; }

[data-aos][data-aos][data-aos-duration="1400"], body[data-aos-duration="1400"] [data-aos] {
  transition-duration: 1.4s; }

[data-aos][data-aos][data-aos-delay="1400"], body[data-aos-delay="1400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1400"].aos-animate, body[data-aos-delay="1400"] [data-aos].aos-animate {
  transition-delay: 1.4s; }

[data-aos][data-aos][data-aos-duration="1450"], body[data-aos-duration="1450"] [data-aos] {
  transition-duration: 1.45s; }

[data-aos][data-aos][data-aos-delay="1450"], body[data-aos-delay="1450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1450"].aos-animate, body[data-aos-delay="1450"] [data-aos].aos-animate {
  transition-delay: 1.45s; }

[data-aos][data-aos][data-aos-duration="1500"], body[data-aos-duration="1500"] [data-aos] {
  transition-duration: 1.5s; }

[data-aos][data-aos][data-aos-delay="1500"], body[data-aos-delay="1500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1500"].aos-animate, body[data-aos-delay="1500"] [data-aos].aos-animate {
  transition-delay: 1.5s; }

[data-aos][data-aos][data-aos-duration="1550"], body[data-aos-duration="1550"] [data-aos] {
  transition-duration: 1.55s; }

[data-aos][data-aos][data-aos-delay="1550"], body[data-aos-delay="1550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1550"].aos-animate, body[data-aos-delay="1550"] [data-aos].aos-animate {
  transition-delay: 1.55s; }

[data-aos][data-aos][data-aos-duration="1600"], body[data-aos-duration="1600"] [data-aos] {
  transition-duration: 1.6s; }

[data-aos][data-aos][data-aos-delay="1600"], body[data-aos-delay="1600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1600"].aos-animate, body[data-aos-delay="1600"] [data-aos].aos-animate {
  transition-delay: 1.6s; }

[data-aos][data-aos][data-aos-duration="1650"], body[data-aos-duration="1650"] [data-aos] {
  transition-duration: 1.65s; }

[data-aos][data-aos][data-aos-delay="1650"], body[data-aos-delay="1650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1650"].aos-animate, body[data-aos-delay="1650"] [data-aos].aos-animate {
  transition-delay: 1.65s; }

[data-aos][data-aos][data-aos-duration="1700"], body[data-aos-duration="1700"] [data-aos] {
  transition-duration: 1.7s; }

[data-aos][data-aos][data-aos-delay="1700"], body[data-aos-delay="1700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1700"].aos-animate, body[data-aos-delay="1700"] [data-aos].aos-animate {
  transition-delay: 1.7s; }

[data-aos][data-aos][data-aos-duration="1750"], body[data-aos-duration="1750"] [data-aos] {
  transition-duration: 1.75s; }

[data-aos][data-aos][data-aos-delay="1750"], body[data-aos-delay="1750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1750"].aos-animate, body[data-aos-delay="1750"] [data-aos].aos-animate {
  transition-delay: 1.75s; }

[data-aos][data-aos][data-aos-duration="1800"], body[data-aos-duration="1800"] [data-aos] {
  transition-duration: 1.8s; }

[data-aos][data-aos][data-aos-delay="1800"], body[data-aos-delay="1800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1800"].aos-animate, body[data-aos-delay="1800"] [data-aos].aos-animate {
  transition-delay: 1.8s; }

[data-aos][data-aos][data-aos-duration="1850"], body[data-aos-duration="1850"] [data-aos] {
  transition-duration: 1.85s; }

[data-aos][data-aos][data-aos-delay="1850"], body[data-aos-delay="1850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1850"].aos-animate, body[data-aos-delay="1850"] [data-aos].aos-animate {
  transition-delay: 1.85s; }

[data-aos][data-aos][data-aos-duration="1900"], body[data-aos-duration="1900"] [data-aos] {
  transition-duration: 1.9s; }

[data-aos][data-aos][data-aos-delay="1900"], body[data-aos-delay="1900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1900"].aos-animate, body[data-aos-delay="1900"] [data-aos].aos-animate {
  transition-delay: 1.9s; }

[data-aos][data-aos][data-aos-duration="1950"], body[data-aos-duration="1950"] [data-aos] {
  transition-duration: 1.95s; }

[data-aos][data-aos][data-aos-delay="1950"], body[data-aos-delay="1950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="1950"].aos-animate, body[data-aos-delay="1950"] [data-aos].aos-animate {
  transition-delay: 1.95s; }

[data-aos][data-aos][data-aos-duration="2000"], body[data-aos-duration="2000"] [data-aos] {
  transition-duration: 2s; }

[data-aos][data-aos][data-aos-delay="2000"], body[data-aos-delay="2000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2000"].aos-animate, body[data-aos-delay="2000"] [data-aos].aos-animate {
  transition-delay: 2s; }

[data-aos][data-aos][data-aos-duration="2050"], body[data-aos-duration="2050"] [data-aos] {
  transition-duration: 2.05s; }

[data-aos][data-aos][data-aos-delay="2050"], body[data-aos-delay="2050"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2050"].aos-animate, body[data-aos-delay="2050"] [data-aos].aos-animate {
  transition-delay: 2.05s; }

[data-aos][data-aos][data-aos-duration="2100"], body[data-aos-duration="2100"] [data-aos] {
  transition-duration: 2.1s; }

[data-aos][data-aos][data-aos-delay="2100"], body[data-aos-delay="2100"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2100"].aos-animate, body[data-aos-delay="2100"] [data-aos].aos-animate {
  transition-delay: 2.1s; }

[data-aos][data-aos][data-aos-duration="2150"], body[data-aos-duration="2150"] [data-aos] {
  transition-duration: 2.15s; }

[data-aos][data-aos][data-aos-delay="2150"], body[data-aos-delay="2150"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2150"].aos-animate, body[data-aos-delay="2150"] [data-aos].aos-animate {
  transition-delay: 2.15s; }

[data-aos][data-aos][data-aos-duration="2200"], body[data-aos-duration="2200"] [data-aos] {
  transition-duration: 2.2s; }

[data-aos][data-aos][data-aos-delay="2200"], body[data-aos-delay="2200"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2200"].aos-animate, body[data-aos-delay="2200"] [data-aos].aos-animate {
  transition-delay: 2.2s; }

[data-aos][data-aos][data-aos-duration="2250"], body[data-aos-duration="2250"] [data-aos] {
  transition-duration: 2.25s; }

[data-aos][data-aos][data-aos-delay="2250"], body[data-aos-delay="2250"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2250"].aos-animate, body[data-aos-delay="2250"] [data-aos].aos-animate {
  transition-delay: 2.25s; }

[data-aos][data-aos][data-aos-duration="2300"], body[data-aos-duration="2300"] [data-aos] {
  transition-duration: 2.3s; }

[data-aos][data-aos][data-aos-delay="2300"], body[data-aos-delay="2300"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2300"].aos-animate, body[data-aos-delay="2300"] [data-aos].aos-animate {
  transition-delay: 2.3s; }

[data-aos][data-aos][data-aos-duration="2350"], body[data-aos-duration="2350"] [data-aos] {
  transition-duration: 2.35s; }

[data-aos][data-aos][data-aos-delay="2350"], body[data-aos-delay="2350"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2350"].aos-animate, body[data-aos-delay="2350"] [data-aos].aos-animate {
  transition-delay: 2.35s; }

[data-aos][data-aos][data-aos-duration="2400"], body[data-aos-duration="2400"] [data-aos] {
  transition-duration: 2.4s; }

[data-aos][data-aos][data-aos-delay="2400"], body[data-aos-delay="2400"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2400"].aos-animate, body[data-aos-delay="2400"] [data-aos].aos-animate {
  transition-delay: 2.4s; }

[data-aos][data-aos][data-aos-duration="2450"], body[data-aos-duration="2450"] [data-aos] {
  transition-duration: 2.45s; }

[data-aos][data-aos][data-aos-delay="2450"], body[data-aos-delay="2450"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2450"].aos-animate, body[data-aos-delay="2450"] [data-aos].aos-animate {
  transition-delay: 2.45s; }

[data-aos][data-aos][data-aos-duration="2500"], body[data-aos-duration="2500"] [data-aos] {
  transition-duration: 2.5s; }

[data-aos][data-aos][data-aos-delay="2500"], body[data-aos-delay="2500"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2500"].aos-animate, body[data-aos-delay="2500"] [data-aos].aos-animate {
  transition-delay: 2.5s; }

[data-aos][data-aos][data-aos-duration="2550"], body[data-aos-duration="2550"] [data-aos] {
  transition-duration: 2.55s; }

[data-aos][data-aos][data-aos-delay="2550"], body[data-aos-delay="2550"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2550"].aos-animate, body[data-aos-delay="2550"] [data-aos].aos-animate {
  transition-delay: 2.55s; }

[data-aos][data-aos][data-aos-duration="2600"], body[data-aos-duration="2600"] [data-aos] {
  transition-duration: 2.6s; }

[data-aos][data-aos][data-aos-delay="2600"], body[data-aos-delay="2600"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2600"].aos-animate, body[data-aos-delay="2600"] [data-aos].aos-animate {
  transition-delay: 2.6s; }

[data-aos][data-aos][data-aos-duration="2650"], body[data-aos-duration="2650"] [data-aos] {
  transition-duration: 2.65s; }

[data-aos][data-aos][data-aos-delay="2650"], body[data-aos-delay="2650"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2650"].aos-animate, body[data-aos-delay="2650"] [data-aos].aos-animate {
  transition-delay: 2.65s; }

[data-aos][data-aos][data-aos-duration="2700"], body[data-aos-duration="2700"] [data-aos] {
  transition-duration: 2.7s; }

[data-aos][data-aos][data-aos-delay="2700"], body[data-aos-delay="2700"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2700"].aos-animate, body[data-aos-delay="2700"] [data-aos].aos-animate {
  transition-delay: 2.7s; }

[data-aos][data-aos][data-aos-duration="2750"], body[data-aos-duration="2750"] [data-aos] {
  transition-duration: 2.75s; }

[data-aos][data-aos][data-aos-delay="2750"], body[data-aos-delay="2750"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2750"].aos-animate, body[data-aos-delay="2750"] [data-aos].aos-animate {
  transition-delay: 2.75s; }

[data-aos][data-aos][data-aos-duration="2800"], body[data-aos-duration="2800"] [data-aos] {
  transition-duration: 2.8s; }

[data-aos][data-aos][data-aos-delay="2800"], body[data-aos-delay="2800"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2800"].aos-animate, body[data-aos-delay="2800"] [data-aos].aos-animate {
  transition-delay: 2.8s; }

[data-aos][data-aos][data-aos-duration="2850"], body[data-aos-duration="2850"] [data-aos] {
  transition-duration: 2.85s; }

[data-aos][data-aos][data-aos-delay="2850"], body[data-aos-delay="2850"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2850"].aos-animate, body[data-aos-delay="2850"] [data-aos].aos-animate {
  transition-delay: 2.85s; }

[data-aos][data-aos][data-aos-duration="2900"], body[data-aos-duration="2900"] [data-aos] {
  transition-duration: 2.9s; }

[data-aos][data-aos][data-aos-delay="2900"], body[data-aos-delay="2900"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2900"].aos-animate, body[data-aos-delay="2900"] [data-aos].aos-animate {
  transition-delay: 2.9s; }

[data-aos][data-aos][data-aos-duration="2950"], body[data-aos-duration="2950"] [data-aos] {
  transition-duration: 2.95s; }

[data-aos][data-aos][data-aos-delay="2950"], body[data-aos-delay="2950"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="2950"].aos-animate, body[data-aos-delay="2950"] [data-aos].aos-animate {
  transition-delay: 2.95s; }

[data-aos][data-aos][data-aos-duration="3000"], body[data-aos-duration="3000"] [data-aos] {
  transition-duration: 3s; }

[data-aos][data-aos][data-aos-delay="3000"], body[data-aos-delay="3000"] [data-aos] {
  transition-delay: 0; }

[data-aos][data-aos][data-aos-delay="3000"].aos-animate, body[data-aos-delay="3000"] [data-aos].aos-animate {
  transition-delay: 3s; }

[data-aos][data-aos][data-aos-easing=linear], body[data-aos-easing=linear] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75); }

[data-aos][data-aos][data-aos-easing=ease], body[data-aos-easing=ease] [data-aos] {
  transition-timing-function: ease; }

[data-aos][data-aos][data-aos-easing=ease-in], body[data-aos-easing=ease-in] [data-aos] {
  transition-timing-function: ease-in; }

[data-aos][data-aos][data-aos-easing=ease-out], body[data-aos-easing=ease-out] [data-aos] {
  transition-timing-function: ease-out; }

[data-aos][data-aos][data-aos-easing=ease-in-out], body[data-aos-easing=ease-in-out] [data-aos] {
  transition-timing-function: ease-in-out; }

[data-aos][data-aos][data-aos-easing=ease-in-back], body[data-aos-easing=ease-in-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045); }

[data-aos][data-aos][data-aos-easing=ease-out-back], body[data-aos-easing=ease-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275); }

[data-aos][data-aos][data-aos-easing=ease-in-out-back], body[data-aos-easing=ease-in-out-back] [data-aos] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }

[data-aos][data-aos][data-aos-easing=ease-in-sine], body[data-aos-easing=ease-in-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715); }

[data-aos][data-aos][data-aos-easing=ease-out-sine], body[data-aos-easing=ease-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1); }

[data-aos][data-aos][data-aos-easing=ease-in-out-sine], body[data-aos-easing=ease-in-out-sine] [data-aos] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95); }

[data-aos][data-aos][data-aos-easing=ease-in-quad], body[data-aos-easing=ease-in-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quad], body[data-aos-easing=ease-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quad], body[data-aos-easing=ease-in-out-quad] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-cubic], body[data-aos-easing=ease-in-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-cubic], body[data-aos-easing=ease-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-cubic], body[data-aos-easing=ease-in-out-cubic] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos][data-aos][data-aos-easing=ease-in-quart], body[data-aos-easing=ease-in-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53); }

[data-aos][data-aos][data-aos-easing=ease-out-quart], body[data-aos-easing=ease-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94); }

[data-aos][data-aos][data-aos-easing=ease-in-out-quart], body[data-aos-easing=ease-in-out-quart] [data-aos] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955); }

[data-aos^=fade][data-aos^=fade] {
  opacity: 0;
  transition-property: opacity,transform; }

[data-aos^=fade][data-aos^=fade].aos-animate {
  opacity: 1;
  transform: translateZ(0); }

[data-aos=fade-up] {
  transform: translate3d(0, 100px, 0); }

[data-aos=fade-down] {
  transform: translate3d(0, -100px, 0); }

[data-aos=fade-right] {
  transform: translate3d(-100px, 0, 0); }

[data-aos=fade-left] {
  transform: translate3d(100px, 0, 0); }

[data-aos=fade-up-right] {
  transform: translate3d(-100px, 100px, 0); }

[data-aos=fade-up-left] {
  transform: translate3d(100px, 100px, 0); }

[data-aos=fade-down-right] {
  transform: translate3d(-100px, -100px, 0); }

[data-aos=fade-down-left] {
  transform: translate3d(100px, -100px, 0); }

[data-aos^=zoom][data-aos^=zoom] {
  opacity: 0;
  transition-property: opacity,transform; }

[data-aos^=zoom][data-aos^=zoom].aos-animate {
  opacity: 1;
  transform: translateZ(0) scale(1); }

[data-aos=zoom-in] {
  transform: scale(0.6); }

[data-aos=zoom-in-up] {
  transform: translate3d(0, 100px, 0) scale(0.6); }

[data-aos=zoom-in-down] {
  transform: translate3d(0, -100px, 0) scale(0.6); }

[data-aos=zoom-in-right] {
  transform: translate3d(-100px, 0, 0) scale(0.6); }

[data-aos=zoom-in-left] {
  transform: translate3d(100px, 0, 0) scale(0.6); }

[data-aos=zoom-out] {
  transform: scale(1.2); }

[data-aos=zoom-out-up] {
  transform: translate3d(0, 100px, 0) scale(1.2); }

[data-aos=zoom-out-down] {
  transform: translate3d(0, -100px, 0) scale(1.2); }

[data-aos=zoom-out-right] {
  transform: translate3d(-100px, 0, 0) scale(1.2); }

[data-aos=zoom-out-left] {
  transform: translate3d(100px, 0, 0) scale(1.2); }

[data-aos^=slide][data-aos^=slide] {
  transition-property: transform; }

[data-aos^=slide][data-aos^=slide].aos-animate {
  transform: translateZ(0); }

[data-aos=slide-up] {
  transform: translate3d(0, 100%, 0); }

[data-aos=slide-down] {
  transform: translate3d(0, -100%, 0); }

[data-aos=slide-right] {
  transform: translate3d(-100%, 0, 0); }

[data-aos=slide-left] {
  transform: translate3d(100%, 0, 0); }

[data-aos^=flip][data-aos^=flip] {
  backface-visibility: hidden;
  transition-property: transform; }

[data-aos=flip-left] {
  transform: perspective(2500px) rotateY(-100deg); }

[data-aos=flip-left].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-right] {
  transform: perspective(2500px) rotateY(100deg); }

[data-aos=flip-right].aos-animate {
  transform: perspective(2500px) rotateY(0); }

[data-aos=flip-up] {
  transform: perspective(2500px) rotateX(-100deg); }

[data-aos=flip-up].aos-animate {
  transform: perspective(2500px) rotateX(0); }

[data-aos=flip-down] {
  transform: perspective(2500px) rotateX(100deg); }

[data-aos=flip-down].aos-animate {
  transform: perspective(2500px) rotateX(0); }

body {
  min-width: 375px;
  background: #FCFCFD;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #23262F; }
  body.dark {
    background: #141416;
    color: #FCFCFD; }

a {
  text-decoration: none; }

svg,
img {
  vertical-align: middle; }

@media only screen and (max-width: 767px) {
  .outer {
    overflow: hidden; } }

.center {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px; }
  @media only screen and (max-width: 1023px) {
    .center {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .center {
      padding: 0 32px; } }

body.dark .some-icon {
  display: none; }

.some-icon-dark {
  display: none; }
  body.dark .some-icon-dark {
    display: inline-block; }

.slick-arrow {
  position: absolute;
  z-index: 2;
  width: 24px;
  height: 24px;
  background: none;
  font-size: 0;
  transition: opacity .2s; }
  .slick-arrow svg {
    position: relative;
    z-index: 2; }
    .slick-arrow svg path {
      fill: #777E90;
      transition: fill .2s; }
      body.dark .slick-arrow svg path {
        fill: #777E90; }
  .slick-arrow:hover svg path {
    fill: #23262F; }
    body.dark .slick-arrow:hover svg path {
      fill: #FCFCFD; }
  .slick-arrow.slick-disabled svg path {
    fill: #B1B5C3; }
    body.dark .slick-arrow.slick-disabled svg path {
      fill: #777E90; }

.select {
  float: none;
  width: 100%;
  height: 48px;
  padding: 0 48px 0 16px;
  box-shadow: inset 0 0 0 2px #E6E8EC;
  background: #FCFCFD;
  border-radius: 12px;
  border: none;
  opacity: 1;
  font-size: 14px;
  font-weight: 500;
  line-height: 48px; }
  .select:after {
    display: none; }
  .select:before {
    content: "";
    position: absolute;
    top: 50%;
    right: 8px;
    width: 32px;
    height: 32px;
    transform: translateY(-50%);
    border-radius: 50%;
    box-shadow: inset 0 0 0 2px #E6E8EC;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50%/10px auto;
    transition: transform .2s; }
  body.dark .select {
    background: #141416;
    box-shadow: inset 0 0 0 2px #353945; }
    body.dark .select:before {
      box-shadow: inset 0 0 0 2px #353945;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='6' fill='none' viewBox='0 0 10 6'%3E%3Cpath fill-rule='evenodd' d='M9.207.793a1 1 0 0 0-1.414 0L5 3.586 2.207.793A1 1 0 1 0 .793 2.207l3.5 3.5a1 1 0 0 0 1.414 0l3.5-3.5a1 1 0 0 0 0-1.414z' fill='%23FCFCFD'/%3E%3C/svg%3E"); }
  .select.open {
    box-shadow: inset 0 0 0 2px #777E90; }
    .select.open:before {
      transform: translateY(-50%) rotate(180deg); }
  .select .current {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .select .list {
    right: 0;
    border-radius: 12px;
    background: #FCFCFD;
    border: 2px solid #E6E8EC;
    box-shadow: 0 4px 12px rgba(35, 38, 47, 0.1); }
    body.dark .select .list {
      background: #141416;
      border-color: #353945;
      box-shadow: 0 4px 12px rgba(20, 20, 22, 0.1); }
  .select .option {
    min-height: auto;
    padding: 10px 14px;
    font-weight: 500;
    line-height: 1.4; }
    .select .option:hover, .select .option.focus, .select .option.selected.focus {
      background: #F4F5F6; }
      body.dark .select .option:hover, body.dark .select .option.focus, body.dark .select .option.selected.focus {
        background: #23262F; }
    .select .option.selected {
      font-weight: 500;
      color: #3772FF; }

.text-right {
  text-align: right; }

.text-center {
  text-align: center; }

.text-uppercase {
  text-transform: uppercase; }

.m-auto {
  margin: auto; }

.ml-auto {
  margin-left: auto; }

.mr-auto {
  margin-right: auto; }

.align-baseline {
  align-items: baseline; }

@media only screen and (max-width: 1179px) {
  .desktop-hide {
    display: none !important; } }

.desktop-show {
  display: none !important; }
  @media only screen and (max-width: 1179px) {
    .desktop-show {
      display: block !important; } }

@media only screen and (max-width: 1179px) {
  .desktop-text-right {
    text-align: right !important; } }

@media only screen and (max-width: 1023px) {
  .tablet-hide {
    display: none !important; } }

.tablet-show {
  display: none !important; }
  @media only screen and (max-width: 1023px) {
    .tablet-show {
      display: block !important; } }

@media only screen and (max-width: 1023px) {
  .tablet-text-right {
    text-align: right !important; } }

@media only screen and (max-width: 767px) {
  .mobile-hide {
    display: none !important; } }

.mobile-show {
  display: none !important; }
  @media only screen and (max-width: 767px) {
    .mobile-show {
      display: block !important; } }

@media only screen and (max-width: 767px) {
  .mobile-text-right {
    text-align: right !important; } }

[class^="section"] {
  padding: 136px 0; }
  @media only screen and (max-width: 1179px) {
    [class^="section"] {
      padding: 112px 0; } }
  @media only screen and (max-width: 767px) {
    [class^="section"] {
      padding: 64px 0; } }

.section-bg {
  background: #F4F5F6; }
  body.dark .section-bg {
    background: #23262F; }

.section-border-top {
  border-top: 1px solid #E6E8EC; }
  body.dark .section-border-top {
    border-color: #353945; }

.section-pb {
  padding-top: 0; }
  @media only screen and (max-width: 1179px) {
    .section-pb {
      padding-top: 0; } }
  @media only screen and (max-width: 767px) {
    .section-pb {
      padding-top: 0; } }

.section-pb64 {
  padding: 0 0 64px; }
  @media only screen and (max-width: 1179px) {
    .section-pb64 {
      padding: 0 0 64px; } }

.container {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 80px; }
  @media only screen and (max-width: 1023px) {
    .container {
      padding: 0 40px; } }
  @media only screen and (max-width: 767px) {
    .container {
      padding: 0 32px; } }

[class^="button"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  background: #FF592C;
  border-radius: 24px;
  font-family: 'SFProDisplay-Bold';
  font-size: 16px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #FCFCFD;
  transition: all .2s; }
  [class^="button"]:hover {
    background: #f83500; }
  [class^="button"].disabled {
    opacity: .5; }
  [class^="button"] .icon {
    width: 10px;
    height: 10px;
    fill: #FCFCFD; }
  [class^="button"]:not([class^="button-circle"]) .icon:last-child {
    margin-left: 15px; }

.button-stroke {
  background: none;
  box-shadow: 0 0 0 2px #E6E8EC inset;
  color: #23262F; }
  .button-stroke .icon {
    fill: #23262F;
    transition: fill .2s; }
  .button-stroke:hover {
    box-shadow: 0 0 0 2px #23262F inset;
    background: #23262F;
    color: #FCFCFD; }
    .button-stroke:hover .icon {
      fill: #FCFCFD; }
  body.dark .button-stroke {
    box-shadow: 0 0 0 2px #777E90 inset;
    color: #FCFCFD; }
    body.dark .button-stroke .icon {
      fill: #FCFCFD; }
    body.dark .button-stroke:hover {
      box-shadow: 0 0 0 2px #FCFCFD inset;
      background: none; }

.button-small {
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  font-size: 14px; }

.button-circle-stroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 2px #E6E8EC inset;
  background: transparent;
  transition: border-color .2s; }
  .button-circle-stroke .icon {
    width: 22px;
    height: 22px;
    fill: #777E90;
    transition: fill .2s; }
  .button-circle-stroke:hover {
    background: #23262F;
    box-shadow: 0 0 0 2px #23262F inset; }
    .button-circle-stroke:hover .icon {
      fill: #FCFCFD; }
  body.dark .button-circle-stroke {
    box-shadow: 0 0 0 2px #353945 inset; }
    body.dark .button-circle-stroke:hover {
      background: #353945; }

.hero,
.h1,
.h2,
.h3,
.h4 {
  font-family: 'SFProDisplay-Bold';
  font-weight: 700; }

.hero {
  font-size: 96px;
  line-height: 1;
  letter-spacing: -.02em; }
  @media only screen and (max-width: 767px) {
    .hero {
      font-size: 64px; } }

.h1 {
  font-size: 64px;
  line-height: 1;
  letter-spacing: -.02em; }
  @media only screen and (max-width: 1023px) {
    .h1 {
      font-size: 48px;
      line-height: 1.16667; } }

.h2 {
  font-size: 48px;
  line-height: 1.16667;
  letter-spacing: -.02em; }
  @media only screen and (max-width: 1023px) {
    .h2 {
      font-size: 40px;
      line-height: 1.2; } }

.h3 {
  font-size: 40px;
  line-height: 1.2;
  letter-spacing: -.01em; }
  @media only screen and (max-width: 1023px) {
    .h3 {
      font-size: 32px;
      line-height: 1.25; } }

.h4 {
  font-size: 32px;
  line-height: 1.25;
  letter-spacing: -.01em; }

[class^="stage"] {
  margin-bottom: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #777E90; }
  @media only screen and (max-width: 767px) {
    [class^="stage"] {
      font-size: 12px; } }

.stage-small {
  font-size: 12px; }

[class^="play"] {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  transform: translate(-50%, -50%);
  box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.2);
  border-radius: 50%;
  background: #FCFCFD; }
  @media only screen and (max-width: 767px) {
    [class^="play"] {
      width: 48px;
      height: 48px; } }
  [class^="play"] .icon {
    width: 21px;
    height: 21px;
    fill: #777E90;
    transition: all .2s; }
    @media only screen and (max-width: 767px) {
      [class^="play"] .icon {
        width: 12px;
        height: 12px; } }
  [class^="play"]:hover .icon {
    transform: scale(1.3);
    fill: #3772FF; }

.play-small {
  width: 48px;
  height: 48px; }
  .play-small .icon {
    width: 12px;
    height: 12px; }

[class^="status"] {
  display: inline-block;
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: #FCFCFD; }

.status-red {
  background: #FF592C; }

.status-green {
  background: #45B26B; }

.status-pink {
  background: #EF466F; }

.status-black {
  background: #23262F; }
  body.dark .status-black {
    background: #FCFCFD;
    color: #23262F; }

.status-purple {
  background: #9757D7; }

.status-white {
  background: #FCFCFD;
  color: #23262F; }

.status-stroke-red {
  box-shadow: inset 0 0 0 2px #FF592C;
  color: #FF592C; }

.status-stroke-green {
  box-shadow: inset 0 0 0 2px #45B26B;
  color: #45B26B; }

.status-stroke-pink {
  box-shadow: inset 0 0 0 2px #EF466F;
  color: #EF466F; }

.status-stroke-black {
  box-shadow: inset 0 0 0 2px #23262F;
  color: #23262F; }
  body.dark .status-stroke-black {
    box-shadow: inset 0 0 0 2px #FCFCFD;
    color: #FCFCFD; }

.status-stroke-purple {
  box-shadow: inset 0 0 0 2px #9757D7;
  color: #9757D7; }

.scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  background: none;
  overflow: hidden;
  border: 2px solid #E6E8EC;
  transition: border-color .2s; }
  @media only screen and (max-width: 1179px) {
    .scroll {
      width: 40px;
      height: 40px; } }
  .scroll .icon {
    width: 14px;
    height: 14px;
    fill: #777E90;
    transition: fill .2s; }
  .scroll:hover {
    border-color: #23262F; }
    .scroll:hover .icon {
      fill: #23262F; }
  body.dark .scroll {
    border-color: #353945; }
    body.dark .scroll:hover {
      border-color: #FCFCFD; }
      body.dark .scroll:hover .icon {
        fill: #FCFCFD; }
  .scroll__line {
    display: flex;
    flex-direction: column;
    transform: translateY(-33px);
    animation: arrowsDown ease-out 1.4s infinite; }
    @media only screen and (max-width: 1179px) {
      .scroll__line {
        transform: translateY(-22px);
        animation-name: arrowsDownSmall; } }
    .scroll__line .icon:not(:last-child) {
      margin-bottom: 50px; }
      @media only screen and (max-width: 1179px) {
        .scroll__line .icon:not(:last-child) {
          margin-bottom: 30px; } }

@keyframes arrowsDown {
  80% {
    transform: translateY(33px); }
  100% {
    transform: translateY(33px); } }

@keyframes arrowsDownSmall {
  80% {
    transform: translateY(22px); }
  100% {
    transform: translateY(22px); } }

[data-aos="animation-opacity"] {
  opacity: 0;
  transition-property: opacity; }
  [data-aos="animation-opacity"].aos-animate {
    opacity: 1; }

[data-aos="animation-translate-up"] {
  transform: translateY(40px);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="animation-translate-up"].aos-animate {
    transform: translate(0);
    opacity: 1; }

[data-aos="animation-translate-down"] {
  transform: translateY(-40px);
  opacity: 0;
  transition-property: transform, opacity; }
  [data-aos="animation-translate-down"].aos-animate {
    transform: translate(0);
    opacity: 1; }

.header {
  position: relative;
  z-index: 10;
  padding: 48px 0; }
  @media only screen and (max-width: 767px) {
    .header {
      padding-bottom: 24px; } }
  .header__center {
    display: flex;
    align-items: center; }
  @media only screen and (max-width: 767px) {
    .header__center > .header__button {
      display: none; } }
  .header__logo {
    margin-right: 48px; }
    @media only screen and (max-width: 1179px) {
      .header__logo {
        margin-right: auto; } }
  .header__wrap {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding-left: 48px;
    border-left: 1px solid #E6E8EC; }
    @media only screen and (max-width: 1339px) {
      .header__wrap {
        padding-left: 0;
        border: none; } }
    @media only screen and (max-width: 1179px) {
      .header__wrap {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        align-items: flex-start;
        padding: 0 80px 80px;
        background: #FCFCFD;
        box-shadow: 0px 64px 64px 0 rgba(15, 15, 15, 0.1);
        visibility: hidden;
        opacity: 0;
        transition: all .2s; } }
    @media only screen and (max-width: 1023px) {
      .header__wrap {
        padding: 0 40px 64px; } }
    @media only screen and (max-width: 767px) {
      .header__wrap {
        flex-direction: column;
        align-items: flex-start;
        min-height: calc((var(--vh, 1vh) * 100) - 104px);
        padding: 48px 32px 44px; } }
    body.dark .header__wrap {
      border-color: #353945; }
      @media only screen and (max-width: 1179px) {
        body.dark .header__wrap {
          background: #141416; } }
    @media only screen and (max-width: 1179px) {
      .header__wrap.visible {
        visibility: visible;
        opacity: 1; } }
  .header__wrap .header__button {
    display: none; }
    @media only screen and (max-width: 767px) {
      .header__wrap .header__button {
        display: inline-flex;
        margin-top: 48px; } }
  .header__nav {
    display: flex;
    align-items: center;
    margin-right: 20px; }
    @media only screen and (max-width: 1179px) {
      .header__nav {
        flex-direction: column;
        align-items: flex-start;
        flex: 0 0 calc(100% - 416px);
        max-width: calc(100% - 416px);
        margin-right: 0; } }
    @media only screen and (max-width: 1023px) {
      .header__nav {
        flex: 0 0 calc(100% - 344px);
        max-width: calc(100% - 344px); } }
    @media only screen and (max-width: 767px) {
      .header__nav {
        align-items: stretch;
        flex: auto 0 calc(100% + 64px);
        width: calc(100% + 64px);
        max-width: calc(100% + 64px);
        margin: 0 -32px auto; } }
  .header__link:not(:last-child), .header__dropdown:not(:last-child) {
    margin-right: 32px; }
    @media only screen and (max-width: 1339px) {
      .header__link:not(:last-child), .header__dropdown:not(:last-child) {
        margin-right: 24px; } }
    @media only screen and (max-width: 1179px) {
      .header__link:not(:last-child), .header__dropdown:not(:last-child) {
        margin: 0 0 48px; } }
    @media only screen and (max-width: 767px) {
      .header__link:not(:last-child), .header__dropdown:not(:last-child) {
        margin: 0; } }
  .header__link {
    display: block;
    padding: 16px 8px;
    font-family: 'SFProDisplay-Bold';
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s; }
    @media only screen and (max-width: 1179px) {
      .header__link {
        padding: 0;
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: -.01em; } }
    @media only screen and (max-width: 1023px) {
      .header__link {
        font-size: 32px; } }
    @media only screen and (max-width: 767px) {
      .header__link {
        padding: 18px 32px;
        font-size: 24px; } }
    .header__link:hover {
      color: #23262F; }
      body.dark .header__link:hover {
        color: #FCFCFD; }
    .header__link.active {
      color: #23262F; }
      @media only screen and (max-width: 767px) {
        .header__link.active {
          box-shadow: inset 2px 0 0 0 #FF592C; } }
      body.dark .header__link.active {
        color: #FCFCFD; }
  .header__head {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 16px 8px;
    font-family: 'SFProDisplay-Bold';
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s;
    user-select: none;
    cursor: pointer; }
    @media only screen and (max-width: 1179px) {
      .header__head {
        padding: 0 50px 0 0;
        font-size: 40px;
        line-height: 1.2;
        letter-spacing: -.01em; } }
    @media only screen and (max-width: 1023px) {
      .header__head {
        font-size: 32px; } }
    @media only screen and (max-width: 767px) {
      .header__head {
        display: flex;
        padding: 18px 42px 18px 32px;
        font-size: 24px; } }
    .header__head .icon {
      margin-left: 12px;
      width: 10px;
      height: 10px;
      fill: #777E90;
      transition: all .2s; }
      @media only screen and (max-width: 1179px) {
        .header__head .icon {
          width: 15px;
          height: 15px;
          margin-left: 50px; } }
      @media only screen and (max-width: 767px) {
        .header__head .icon {
          width: 10px;
          height: 10px;
          margin-left: auto; } }
    .header__head:hover {
      color: #23262F; }
      .header__head:hover .icon {
        fill: #23262F; }
      body.dark .header__head:hover {
        color: #FCFCFD; }
        body.dark .header__head:hover .icon {
          fill: #FCFCFD; }
  .header__dropdown.open .header__head,
  .header__dropdown.active .header__head {
    color: #23262F; }
    body.dark .header__dropdown.open .header__head, body.dark
    .header__dropdown.active .header__head {
      color: #FCFCFD; }
    @media only screen and (max-width: 767px) {
      .header__dropdown.open .header__head,
      .header__dropdown.active .header__head {
        box-shadow: inset 2px 0 0 0 #FF592C; } }
    .header__dropdown.open .header__head .icon,
    .header__dropdown.active .header__head .icon {
      fill: #23262F; }
      body.dark .header__dropdown.open .header__head .icon, body.dark
      .header__dropdown.active .header__head .icon {
        fill: #FCFCFD; }
  .header__dropdown.open .header__head .icon {
    transform: rotate(180deg); }
  .header__body {
    position: absolute;
    top: 100%;
    left: 50%;
    width: 1120px;
    visibility: hidden;
    opacity: 0;
    transform: translate(-50%, 10px) scale(0.9);
    transform-origin: 50% -50px;
    will-change: transform;
    transition: all .4s; }
    @media only screen and (max-width: 1179px) {
      .header__body {
        display: none;
        position: static;
        width: auto;
        margin-right: -416px;
        padding-top: 48px;
        transform: translate(0, 0) scale(1);
        visibility: visible;
        opacity: 1;
        animation: none; } }
    @media only screen and (max-width: 1023px) {
      .header__body {
        margin-right: -344px; } }
    @media only screen and (max-width: 767px) {
      .header__body {
        margin-right: 0;
        padding-top: 0; } }
    body.dark .header__body {
      border-color: #353945; }
  .header__dropdown.open .header__body {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, 0) scale(1); }
    @media only screen and (max-width: 1179px) {
      .header__dropdown.open .header__body {
        display: block;
        transform: translate(0, 0) scale(1); } }
  .header__inner {
    max-width: 1120px;
    margin: 0 auto;
    background: #FCFCFD;
    border-radius: 24px;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    border: 1px solid #E6E8EC; }
    @media only screen and (max-width: 1179px) {
      .header__inner {
        max-width: 100%;
        margin: 0;
        box-shadow: 0px 24px 24px -24px rgba(15, 15, 15, 0.1); } }
    @media only screen and (max-width: 767px) {
      .header__inner {
        padding: 0 32px;
        box-shadow: none;
        border: none; } }
    body.dark .header__inner {
      background: #141416;
      border-color: #353945;
      box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1); }
  .header__row {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .header__row {
        display: block; } }
  .header__col {
    padding: 40px; }
    @media only screen and (max-width: 1179px) {
      .header__col {
        padding: 32px; } }
    @media only screen and (max-width: 767px) {
      .header__col {
        padding: 0; } }
    .header__col:first-child {
      display: flex;
      flex-direction: column;
      flex: 0 0 256px; }
      @media only screen and (max-width: 1179px) {
        .header__col:first-child {
          flex: 0 0 380px; } }
      @media only screen and (max-width: 1023px) {
        .header__col:first-child {
          flex: 0 0 280px; } }
    .header__col:nth-child(2) {
      flex-grow: 1;
      border-left: 1px solid #E6E8EC; }
      @media only screen and (max-width: 767px) {
        .header__col:nth-child(2) {
          display: none; } }
      body.dark .header__col:nth-child(2) {
        border-color: #353945; }
  .header__direction {
    margin: -16px -16px 32px;
    padding: 16px;
    border-radius: 8px;
    transition: background .2s; }
    @media only screen and (max-width: 767px) {
      .header__direction {
        display: none; } }
    .header__direction:hover {
      background: #F4F5F6; }
      body.dark .header__direction:hover {
        background: #23262F; }
  .header__info {
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600;
    color: #23262F; }
    body.dark .header__info {
      color: #FCFCFD; }
  .header__preview {
    margin-top: 24px; }
    @media only screen and (max-width: 1023px) {
      .header__preview {
        display: none; } }
    .header__preview img {
      max-width: 80px; }
  .header__menu {
    margin: auto -16px 0; }
  .header__box {
    display: flex;
    align-items: center;
    padding: 16px 8px 16px 16px;
    border-radius: 8px;
    font-weight: 600;
    color: #23262F;
    transition: all .2s; }
    body.dark .header__box {
      color: #FCFCFD; }
      @media only screen and (max-width: 767px) {
        body.dark .header__box {
          color: #777E90; } }
    .header__box:hover, .header__box.active {
      background: #F4F5F6; }
      body.dark .header__box:hover, body.dark .header__box.active {
        background: #23262F; }
        @media only screen and (max-width: 767px) {
          body.dark .header__box:hover, body.dark .header__box.active {
            color: #FCFCFD; } }
    .header__box:not(:last-child) {
      margin-bottom: 8px; }
      @media only screen and (max-width: 767px) {
        .header__box:not(:last-child) {
          margin-bottom: 0; } }
  .header__icon {
    flex-shrink: 0;
    width: 32px;
    margin-right: 12px; }
    .header__icon img {
      max-width: 100%; }
  .header__line {
    display: flex;
    margin: 0 -20px; }
  .header__cell {
    flex: 0 0 calc(50% - 40px);
    width: calc(50% - 40px);
    margin: 0 20px; }
    @media only screen and (max-width: 1179px) {
      .header__cell {
        flex: 0 0 100%;
        width: 100%; } }
    @media only screen and (max-width: 1179px) {
      .header__cell:first-child {
        display: none; } }
  .header__card, .header__item {
    color: #23262F; }
    body.dark .header__card, body.dark .header__item {
      color: #FCFCFD; }
  .header__card:hover .header__photo,
  .header__item:hover .header__photo {
    box-shadow: 0 0 0 3px #FF592C; }
    .header__card:hover .header__photo img,
    .header__item:hover .header__photo img {
      transform: scale(1.05); }
  .header__card {
    display: block; }
    .header__card .header__photo {
      position: relative;
      margin-bottom: 24px;
      border-radius: 16px; }
      .header__card .header__photo .header__category {
        position: absolute;
        top: 8px;
        left: 8px; }
    .header__card .header__user {
      margin-right: auto; }
    .header__card .header__title {
      font-size: 24px;
      line-height: 1.33333;
      font-weight: 600; }
  .header__photo {
    overflow: hidden;
    transition: box-shadow .2s; }
    .header__photo img {
      width: 100%;
      transition: transform 1.4s; }
  .header__top {
    display: flex;
    align-items: flex-start; }
  .header__level {
    flex-shrink: 0;
    margin-left: 10px; }
  .header__user {
    display: flex; }
  .header__avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin: 5px 16px 0 0;
    border-radius: 50%;
    background: #E6E8EC;
    overflow: hidden; }
    body.dark .header__avatar {
      background: #353945; }
    .header__avatar img {
      width: 100%;
      height: 100%; }
  .header__title {
    margin-bottom: 2px;
    font-weight: 600; }
  .header__trainer {
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
  .header__content {
    margin-top: 12px;
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
  .header__item {
    display: flex;
    align-items: center; }
    .header__item .header__photo {
      flex-shrink: 0;
      width: 140px;
      margin-right: 24px;
      border-radius: 8px; }
      @media only screen and (max-width: 1179px) {
        .header__item .header__photo {
          width: 110px; } }
    .header__item .header__title {
      font-weight: 600; }
    .header__item .header__category {
      margin-bottom: 12px; }
      @media only screen and (max-width: 1179px) {
        .header__item .header__category {
          margin-bottom: 8px; } }
    .header__item:not(:last-child) {
      margin-bottom: 32px; }
      @media only screen and (max-width: 1179px) {
        .header__item:not(:last-child) {
          margin-bottom: 24px; } }
  .header__foot {
    padding: 40px;
    border-top: 1px solid #E6E8EC; }
    body.dark .header__foot {
      border-color: #353945; }
    @media only screen and (max-width: 1179px) {
      .header__foot {
        padding: 32px; } }
    @media only screen and (max-width: 767px) {
      .header__foot {
        display: none; } }
  .header__stage {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600; }
  .header__list {
    display: flex;
    margin: 0 -16px; }
    .header__list .header__user {
      align-items: center;
      flex-grow: 1;
      margin: 0 16px; }
      @media only screen and (max-width: 1179px) {
        .header__list .header__user:nth-child(n+4) {
          display: none; } }
      @media only screen and (max-width: 1023px) {
        .header__list .header__user:nth-child(n+3) {
          display: none; } }
    .header__list .header__avatar {
      width: 56px;
      height: 56px; }
  .header__group {
    display: flex;
    align-items: center;
    flex-grow: 1; }
    @media only screen and (max-width: 1179px) {
      .header__group {
        display: block;
        flex: 0 0 416px; } }
    @media only screen and (max-width: 1023px) {
      .header__group {
        flex: 0 0 344px; } }
    @media only screen and (max-width: 767px) {
      .header__group {
        flex: 0 0 auto; } }
  .header__contacts {
    display: none; }
    @media only screen and (max-width: 1179px) {
      .header__contacts {
        display: block;
        margin-bottom: 32px; } }
    @media only screen and (max-width: 767px) {
      .header__contacts {
        display: none; } }
  .header__element {
    margin-bottom: 32px; }
    @media only screen and (max-width: 1023px) {
      .header__element {
        margin-bottom: 24px; } }
  .header__subtitle {
    margin-bottom: 8px;
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: -.01em; }
    @media only screen and (max-width: 1023px) {
      .header__subtitle {
        margin-bottom: 4px;
        font-size: 18px; } }
  .header__element .header__text {
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
  .header__socials {
    display: flex;
    margin: 0 auto; }
    @media only screen and (max-width: 767px) {
      .header__socials {
        display: none; } }
  .header__social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 24px;
    height: 24px; }
    .header__social .icon {
      width: 16px;
      height: 16px;
      fill: #777E90;
      transition: fill .2s; }
      .header__social .icon-twitter {
        width: 18px;
        height: 18px; }
    .header__social:hover .icon {
      fill: #23262F; }
      body.dark .header__social:hover .icon {
        fill: #FCFCFD; }
    .header__social:not(:last-child) {
      margin-right: 24px; }
  .header__burger {
    display: none; }
    @media only screen and (max-width: 1179px) {
      .header__burger {
        display: block;
        position: relative;
        margin-left: 40px;
        width: 32px;
        height: 32px;
        background: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
        .header__burger.active:before {
          transform: translateY(0) rotate(-45deg); }
        .header__burger.active:after {
          transform: translateY(0) rotate(45deg); }
        .header__burger:before, .header__burger:after {
          content: '';
          position: absolute;
          top: 16px;
          left: 6px;
          width: 20px;
          height: 2px;
          background: #777E90;
          border-radius: 2px;
          transition: transform .2s; }
        .header__burger:before {
          transform: translateY(-4px); }
        .header__burger:after {
          transform: translateY(3px); } }
    @media only screen and (max-width: 1023px) {
      .header__burger {
        margin-left: 32px; } }

.main {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 880px;
  padding: 34px 0 130px; }
  @media only screen and (max-width: 1339px) {
    .main {
      min-height: 800px; } }
  @media only screen and (max-width: 1179px) {
    .main {
      min-height: 624px; } }
  @media only screen and (max-width: 767px) {
    .main {
      min-height: auto;
      padding: 64px 0 152px; } }
  .main__wrap {
    position: relative;
    z-index: 3;
    max-width: 450px; }
    @media only screen and (max-width: 1023px) {
      .main__wrap {
        max-width: 340px; } }
    @media only screen and (max-width: 767px) {
      .main__wrap {
        max-width: 100%;
        margin-bottom: 80px; } }
  .main__title {
    margin-bottom: 20px; }
  .main__text {
    margin-bottom: 40px;
    color: #777E90; }
  .main__btns {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .main__btns {
        display: block; } }
  @media only screen and (max-width: 767px) {
    .main__button {
      width: 100%; } }
  .main__button:not(:last-child) {
    margin-right: 16px; }
    @media only screen and (max-width: 767px) {
      .main__button:not(:last-child) {
        margin: 0 0 16px; } }
  .main .scroll {
    position: absolute;
    bottom: 96px; }
    @media only screen and (max-width: 1179px) {
      .main .scroll {
        bottom: 48px; } }
    @media only screen and (max-width: 767px) {
      .main .scroll {
        bottom: 64px; } }
  .main__gallery {
    position: absolute;
    top: 50%;
    right: calc(50% - 465px);
    width: 512px;
    transform: translateY(-58%);
    pointer-events: none; }
    @media only screen and (max-width: 1339px) {
      .main__gallery {
        right: calc(50% - 425px);
        width: 450px; } }
    @media only screen and (max-width: 1179px) {
      .main__gallery {
        right: calc(50% - 365px);
        width: 320px; } }
    @media only screen and (max-width: 1023px) {
      .main__gallery {
        right: calc(50% - 300px); } }
    @media only screen and (max-width: 767px) {
      .main__gallery {
        position: relative;
        top: auto;
        right: auto;
        left: -16px;
        margin: 0 auto;
        transform: translate(0, 0);
        pointer-events: all; } }
  .main__preview:first-child {
    position: relative;
    z-index: 2; }
  .main__preview:nth-child(2) {
    top: 36%;
    right: -18%;
    z-index: 3;
    width: 45%; }
  .main__preview:nth-child(3) {
    top: -12%;
    right: -7%;
    z-index: 1;
    width: 45%; }
  .main__preview:nth-child(4) {
    left: 11%;
    bottom: 13%;
    z-index: 3;
    width: 18%; }
  .main__preview:not(:first-child) {
    position: absolute; }
  .main__preview img {
    width: 100%; }

.main1 {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 880px;
  padding: 34px 0 180px;
  overflow: hidden; }
  @media only screen and (max-width: 1179px) {
    .main1 {
      min-height: 606px;
      padding: 80px 0; } }
  @media only screen and (max-width: 767px) {
    .main1 {
      min-height: auto;
      padding: 64px 0 0; } }
  .main1__wrap {
    position: relative;
    z-index: 3;
    max-width: 450px; }
    @media only screen and (max-width: 1023px) {
      .main1__wrap {
        max-width: 350px; } }
    @media only screen and (max-width: 767px) {
      .main1__wrap {
        max-width: 100%;
        margin-bottom: 32px; } }
  .main1__title {
    margin-bottom: 20px; }
  .main1__text {
    margin-bottom: 40px;
    color: #777E90; }
  .main1__btns {
    display: flex; }
  @media only screen and (max-width: 767px) {
    .main1__button {
      padding: 0 22px; } }
  .main1__button:not(:last-child) {
    margin-right: 16px; }
  .main1 .scroll {
    position: absolute;
    bottom: 96px; }
    @media only screen and (max-width: 1179px) {
      .main1 .scroll {
        display: none; } }
  .main1__gallery {
    position: absolute;
    top: 50%;
    right: calc(50% - 720px);
    width: 800px;
    transform: translateY(-50%);
    pointer-events: none; }
    @media only screen and (max-width: 1179px) {
      .main1__gallery {
        top: 54%;
        right: calc(50% - 600px);
        width: 600px; } }
    @media only screen and (max-width: 1023px) {
      .main1__gallery {
        right: calc(50% - 520px);
        width: 550px; } }
    @media only screen and (max-width: 767px) {
      .main1__gallery {
        position: relative;
        top: auto;
        right: auto;
        width: 580px;
        margin: 0 auto;
        transform: translateY(0); } }
    @media only screen and (max-width: 474px) {
      .main1__gallery {
        left: -32%; } }
  .main1__preview:first-child {
    position: relative;
    z-index: 2; }
  .main1__preview:nth-child(2) {
    top: 10%;
    right: 20%;
    z-index: 3;
    width: 20%; }
  .main1__preview:nth-child(3) {
    top: 47%;
    left: 12%;
    z-index: 1;
    width: 8.5%; }
  .main1__preview:not(:first-child) {
    position: absolute; }
  .main1__preview img {
    width: 100%; }

.main2__center {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 0; }
  @media only screen and (max-width: 767px) {
    .main2__center {
      display: block; } }

.main2__col {
  flex: 0 0 50%; }
  @media only screen and (max-width: 1179px) {
    .main2__col:first-child {
      flex: 0 0 40%; } }
  @media only screen and (max-width: 1023px) {
    .main2__col:first-child {
      flex: 0 0 45%; } }
  .main2__col:nth-child(2) {
    padding: 0 64px 0 106px; }
    @media only screen and (max-width: 1339px) {
      .main2__col:nth-child(2) {
        padding: 0 40px 0 80px; } }
    @media only screen and (max-width: 1179px) {
      .main2__col:nth-child(2) {
        flex: 0 0 60%; } }
    @media only screen and (max-width: 1023px) {
      .main2__col:nth-child(2) {
        flex: 0 0 55%;
        padding: 0 40px 0 40px; } }
    @media only screen and (max-width: 767px) {
      .main2__col:nth-child(2) {
        padding: 48px 32px 64px; } }

.main2__wrap {
  max-width: 450px; }

.main2__stage {
  margin-bottom: 21px; }

.main2__title {
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 36px; }
  @media only screen and (max-width: 767px) {
    .main2__title {
      margin-bottom: 32px;
      padding-bottom: 32px; } }
  .main2__title:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 128px;
    height: 1px;
    background: #E6E8EC; }
    body.dark .main2__title:after {
      background: #353945; }

.main2__text {
  margin-bottom: 40px;
  color: #777E90; }
  @media only screen and (max-width: 767px) {
    .main2__text {
      margin-bottom: 32px; } }

.main2 .subscription {
  max-width: 300px; }

@media only screen and (min-width: 1800px) {
  .main2__preview {
    height: 880px; } }

.main2__preview img {
  width: 100%;
  max-height: 100%;
  object-fit: cover; }

.main3 {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 880px;
  padding: 34px 0 215px;
  overflow: hidden; }
  @media only screen and (max-width: 1179px) {
    .main3 {
      align-items: flex-start;
      min-height: 784px;
      padding: 96px 0 180px; } }
  @media only screen and (max-width: 767px) {
    .main3 {
      padding: 64px 0 168px; } }
  .main3__wrap {
    position: relative;
    z-index: 3;
    max-width: 450px; }
    @media only screen and (max-width: 1179px) {
      .main3__wrap {
        max-width: 420px; } }
    @media only screen and (max-width: 1023px) {
      .main3__wrap {
        max-width: 320px; } }
    @media only screen and (max-width: 767px) {
      .main3__wrap {
        max-width: 100%; } }
  .main3__title {
    margin-bottom: 20px; }
  .main3__text {
    margin-bottom: 40px;
    color: #777E90; }
  .main3__btns {
    display: flex; }
  .main3__button:not(:last-child) {
    margin-right: 16px; }
  .main3 .scroll {
    position: absolute;
    bottom: 96px; }
    @media only screen and (max-width: 1179px) {
      .main3 .scroll {
        bottom: 80px; } }
    @media only screen and (max-width: 767px) {
      .main3 .scroll {
        bottom: 64px; } }
  .main3__gallery {
    position: absolute;
    top: 50%;
    right: calc(50% - 556px);
    width: 546px;
    transform: translateY(-63%);
    pointer-events: none; }
    @media only screen and (max-width: 1179px) {
      .main3__gallery {
        width: 450px;
        transform: translateY(-65%); } }
    @media only screen and (max-width: 1023px) {
      .main3__gallery {
        right: calc(50% - 450px); } }
    @media only screen and (max-width: 767px) {
      .main3__gallery {
        position: relative;
        top: auto;
        right: auto;
        margin: 40px auto 0;
        transform: translateY(0); } }
    @media only screen and (max-width: 474px) {
      .main3__gallery {
        left: -13%; } }
  .main3__preview:first-child {
    position: relative;
    z-index: 2; }
  .main3__preview:nth-child(2) {
    top: 27%;
    right: -1%;
    z-index: 3;
    width: 27%; }
  .main3__preview:nth-child(3) {
    top: 44%;
    left: 1.5%;
    z-index: 1;
    width: 10%; }
  .main3__preview:nth-child(4) {
    right: 17%;
    bottom: 14%;
    z-index: 1;
    width: 28%;
    transform: rotate(-12deg); }
  .main3__preview:not(:first-child) {
    position: absolute; }
  .main3__preview img {
    width: 100%; }

.clients__title {
  margin-bottom: 40px;
  text-align: center;
  font-weight: 600; }

.clients__list {
  display: flex;
  margin: 0 -12px; }
  @media only screen and (max-width: 1179px) {
    .clients__list {
      margin: 0 -8px; } }
  @media only screen and (max-width: 767px) {
    .clients__list {
      margin: 0 -32px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch; }
      .clients__list::-webkit-scrollbar {
        display: none; }
      .clients__list:before, .clients__list:after {
        content: "";
        flex-shrink: 0;
        width: 32px;
        height: 1px; } }

.clients__logo {
  flex: 1;
  margin: 0 12px; }
  @media only screen and (max-width: 1179px) {
    .clients__logo {
      margin: 0 8px; } }
  @media only screen and (max-width: 767px) {
    .clients__logo {
      flex: 0 0 140px;
      margin: 0; } }
  .clients__logo img {
    width: 100%; }
  @media only screen and (max-width: 767px) {
    .clients__logo:not(:last-child) {
      margin-right: 16px; } }

.steps__head {
  max-width: 400px;
  margin: 0 auto 80px;
  text-align: center; }
  @media only screen and (max-width: 1179px) {
    .steps__head {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .steps__head {
      margin-bottom: 40px; } }

.steps__title {
  margin-bottom: 20px; }

.steps__info {
  color: #353945; }
  body.dark .steps__info {
    color: #E6E8EC; }

.steps__list {
  display: flex;
  margin: 0 -16px; }
  @media only screen and (max-width: 1023px) {
    .steps__list {
      margin: 0 -40px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch; }
      .steps__list::-webkit-scrollbar {
        display: none; }
      .steps__list:before, .steps__list:after {
        content: "";
        flex-shrink: 0;
        width: 40px;
        height: 1px; } }

.steps__item {
  position: relative;
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 0 16px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .steps__item {
      flex: 0 0 256px;
      width: 256px;
      margin: 0; } }
  @media only screen and (max-width: 767px) {
    .steps__item {
      text-align: left; } }
  .steps__item:after {
    content: "";
    position: absolute;
    top: 39px;
    right: -79px;
    width: 120px;
    height: 2px;
    background-image: linear-gradient(90deg, #B1B5C3 0, #B1B5C3 6px, transparent 6px, transparent 14px);
    background-repeat: repeat-x;
    background-size: 12px auto; }
    @media only screen and (max-width: 1260px) {
      .steps__item:after {
        right: -66px;
        width: 100px; } }
    @media only screen and (max-width: 1179px) {
      .steps__item:after {
        right: -50px;
        width: 60px; } }
    @media only screen and (max-width: 1023px) {
      .steps__item:after {
        right: -79px;
        width: 120px; } }
    @media only screen and (max-width: 767px) {
      .steps__item:after {
        right: 11px;
        background: none;
        border-bottom: 2px dashed #B1B5C3; } }
  .steps__item:first-child .steps__preview:before {
    display: none; }
  .steps__item:last-child:after {
    display: none; }
  .steps__item:last-child .steps__preview:after {
    display: none; }
  @media only screen and (max-width: 1023px) {
    .steps__item:not(:last-child) {
      margin-right: 32px; } }

.steps__preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  margin: 0 auto 80px;
  border-radius: 24px; }
  @media only screen and (max-width: 767px) {
    .steps__preview {
      margin: 0 0 32px 0; } }
  .steps__preview:before, .steps__preview:after {
    content: "";
    position: absolute;
    top: 50%;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    border-radius: 50%;
    border: 2px solid #B1B5C3; }
  .steps__preview:before {
    left: -36px; }
  .steps__preview:after {
    right: -36px; }

.steps__number {
  margin-bottom: 32px;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 700;
  color: #777E90; }

.steps__subtitle {
  margin-bottom: 16px;
  font-weight: 600; }

.steps__content {
  font-size: 14px;
  line-height: 1.71429;
  color: #353945; }
  body.dark .steps__content {
    color: #E6E8EC; }

.intro {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 816px;
  padding: 100px 0; }
  @media only screen and (max-width: 1179px) {
    .intro {
      min-height: 600px;
      padding: 80px 0; } }
  @media only screen and (max-width: 767px) {
    .intro {
      display: block;
      min-height: auto;
      padding: 32px 0 64px; } }
  .intro__wrap {
    position: relative;
    z-index: 3;
    max-width: 450px; }
    @media only screen and (max-width: 1023px) {
      .intro__wrap {
        max-width: 360px; } }
  .intro__title {
    margin-bottom: 32px; }
  .intro__text {
    margin-bottom: 40px;
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #777E90; }
  .intro__gallery {
    position: absolute;
    top: 53%;
    right: calc(50% - 541px);
    width: 584px;
    transform: translateY(-40%);
    pointer-events: none; }
    @media only screen and (max-width: 1179px) {
      .intro__gallery {
        right: calc(50% - 440px);
        width: 390px;
        transform: translateY(-44%); } }
    @media only screen and (max-width: 1023px) {
      .intro__gallery {
        right: calc(50% - 380px); } }
    @media only screen and (max-width: 767px) {
      .intro__gallery {
        position: relative;
        top: auto;
        right: auto;
        left: -15px;
        margin: 96px auto 24px;
        transform: translateY(0); } }
  .intro__preview:first-child {
    position: relative;
    z-index: 2; }
  .intro__preview:nth-child(2) {
    top: -27%;
    left: 4%;
    z-index: 1;
    width: 33%; }
  .intro__preview:nth-child(3) {
    top: -18%;
    right: 31%;
    z-index: 1;
    width: 33%; }
  .intro__preview:not(:first-child) {
    position: absolute; }
  .intro__preview img {
    width: 100%; }

.book {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .book {
      padding-top: 0;
      background: none; } }
  @media only screen and (max-width: 767px) {
    .book__center {
      padding: 0 16px; } }
  .book__gallery {
    position: absolute;
    top: 50%;
    left: calc(50% - 640px);
    width: 624px;
    transform: translateY(-50%);
    pointer-events: none; }
    @media only screen and (max-width: 1339px) {
      .book__gallery {
        left: calc(50% - 610px);
        width: 580px; } }
    @media only screen and (max-width: 1179px) {
      .book__gallery {
        left: calc(50% - 560px); } }
    @media only screen and (max-width: 767px) {
      .book__gallery {
        position: relative;
        top: auto;
        left: 50%;
        width: 500px;
        transform: translate(-50%, 0); } }
  .book__preview:first-child {
    position: relative;
    z-index: 2; }
  .book__preview:nth-child(2) {
    top: 14%;
    right: 13%;
    z-index: 3;
    width: 29.5%; }
  .book__preview:nth-child(3) {
    top: 46%;
    left: 15%;
    z-index: 1;
    width: 17%; }
  .book__preview:nth-child(4) {
    right: 3%;
    bottom: 17%;
    z-index: 1;
    width: 33.5%; }
  .book__preview:not(:first-child) {
    position: absolute; }
  .book__preview img {
    width: 100%; }
  .book__wrap {
    position: relative;
    z-index: 3;
    max-width: 545px;
    margin-left: auto;
    padding: 48px;
    background: #FCFCFD;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    border-radius: 20px; }
    @media only screen and (max-width: 1339px) {
      .book__wrap {
        max-width: 500px; } }
    @media only screen and (max-width: 1179px) {
      .book__wrap {
        max-width: 416px;
        padding: 32px; } }
    body.dark .book__wrap {
      background: #141416; }
  .book__title {
    margin-bottom: 24px; }
  .book__info {
    margin-bottom: 24px;
    color: #777E90; }
  .book__list {
    margin-bottom: 24px; }
  .book__item {
    display: flex;
    padding: 24px;
    border-radius: 12px;
    cursor: pointer;
    transition: box-shadow .2s; }
    .book__item:hover {
      box-shadow: inset 0 0 0 2px #E6E8EC; }
      body.dark .book__item:hover {
        box-shadow: inset 0 0 0 2px #353945; }
    .book__item:not(:last-child) {
      margin-bottom: 4px; }
  .book__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 24px;
    border-radius: 50%; }
  .book__details {
    flex-grow: 1; }
  .book__subtitle {
    margin-bottom: 4px;
    font-weight: 600; }
  .book__content {
    max-width: 260px;
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
  .book__note {
    margin-bottom: 24px;
    margin-right: -10px;
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
    @media only screen and (max-width: 767px) {
      .book__note {
        margin: 0 0 24px;
        text-align: center; } }
    .book__note strong {
      font-weight: 600; }
  .book__green {
    color: #45B26B; }
  .book__black {
    color: #23262F; }
    body.dark .book__black {
      color: #FCFCFD; }
  .book__btns {
    text-align: right; }
  @media only screen and (max-width: 767px) {
    .book__button {
      width: 100%; } }

.details__head {
  max-width: 580px;
  margin: 0 auto 80px;
  text-align: center; }
  @media only screen and (max-width: 1179px) {
    .details__head {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .details__head {
      text-align: left; } }

.details__stage {
  margin-bottom: 8px;
  color: #777E90; }

.details__list {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .details__list {
      display: block; } }

.details__item {
  flex: 0 0 31%;
  padding: 34px 83px; }
  @media only screen and (max-width: 1339px) {
    .details__item {
      padding: 34px 64px; } }
  @media only screen and (max-width: 1179px) {
    .details__item {
      padding: 32px; } }
  @media only screen and (max-width: 767px) {
    .details__item {
      padding: 0; } }
  .details__item:first-child {
    padding-left: 0; }
  .details__item:nth-child(2) {
    flex: 0 0 38%;
    border-width: 0 1px;
    border-style: solid;
    border-color: #E6E8EC; }
    @media only screen and (max-width: 767px) {
      .details__item:nth-child(2) {
        border: none; } }
    body.dark .details__item:nth-child(2) {
      border-color: #353945; }
  .details__item:last-child {
    padding-right: 0; }
  @media only screen and (max-width: 767px) {
    .details__item:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid #E6E8EC; }
      body.dark .details__item:not(:last-child) {
        border-color: #353945; } }

.details__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  margin-bottom: 32px; }
  body.dark .details__icon path {
    fill: #FCFCFD; }

.details__category {
  margin-bottom: 32px;
  font-weight: 600; }

.details__content {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.about {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .about {
      padding: 48px 0;
      border: none; } }
  .about__row {
    display: flex;
    margin: 0 -12px; }
    @media only screen and (max-width: 1179px) {
      .about__row {
        margin: 0; } }
    @media only screen and (max-width: 767px) {
      .about__row:first-child {
        display: block; } }
    @media only screen and (max-width: 767px) {
      .about__row:nth-child(2) {
        flex-direction: column-reverse; }
        .about__row:nth-child(2) .about__col:nth-child(2) {
          margin-bottom: 60px; } }
    .about__row:not(:last-child) {
      margin-bottom: 128px; }
      @media only screen and (max-width: 1179px) {
        .about__row:not(:last-child) {
          margin-bottom: 80px; } }
      @media only screen and (max-width: 767px) {
        .about__row:not(:last-child) {
          margin-bottom: 60px; } }
  .about__col {
    flex: 0 0 calc(50% - 24px);
    width: calc(50% - 24px);
    margin: 0 12px; }
    @media only screen and (max-width: 1179px) {
      .about__col {
        margin: 0; } }
    @media only screen and (max-width: 1179px) {
      .about__col:first-child {
        flex: 1 0 auto;
        width: auto;
        padding-right: 32px; } }
    @media only screen and (max-width: 767px) {
      .about__col:first-child {
        flex: 0 0 100%;
        width: 100%;
        padding: 0; } }
    @media only screen and (max-width: 1179px) {
      .about__col:nth-child(2) {
        flex: 0 0 528px;
        width: 528px; } }
    @media only screen and (max-width: 1023px) {
      .about__col:nth-child(2) {
        flex: 0 0 400px;
        width: 400px; } }
    @media only screen and (max-width: 767px) {
      .about__col:nth-child(2) {
        flex: 0 0 100%;
        width: 100%; } }
  .about__title {
    margin-bottom: 20px; }
  .about__info {
    max-width: 450px;
    color: #777E90; }
  .about__list {
    max-width: 260px; }
    @media only screen and (max-width: 767px) {
      .about__list {
        max-width: 100%; } }
  .about__item:not(:last-child) {
    margin-bottom: 48px; }
    @media only screen and (max-width: 1023px) {
      .about__item:not(:last-child) {
        margin-bottom: 32px; } }
  .about__number {
    display: inline-block;
    width: 38px;
    margin-bottom: 24px;
    border-radius: 12px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    color: #FCFCFD; }
    @media only screen and (max-width: 1023px) {
      .about__number {
        margin-bottom: 16px; } }
    @media only screen and (max-width: 767px) {
      .about__number {
        margin-bottom: 24px; } }
  .about__subtitle {
    margin-bottom: 16px;
    font-weight: 500; }
    @media only screen and (max-width: 1023px) {
      .about__subtitle {
        margin-bottom: 12px; } }
    @media only screen and (max-width: 767px) {
      .about__subtitle {
        margin-bottom: 16px; } }
  .about__content {
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
  .about__bg {
    position: relative;
    background: #E6E8EC;
    border-radius: 24px; }
    body.dark .about__bg {
      background: #353945; }
    .about__bg img {
      width: 100%; }
    .about__bg > img {
      border-radius: 24px; }
    @media only screen and (max-width: 1179px) {
      .about__bg_overflow {
        overflow: hidden; } }
  .about__preview {
    position: absolute;
    top: 1.5%;
    left: -31%;
    width: 64.5%;
    pointer-events: none; }
    @media only screen and (max-width: 1179px) {
      .about__preview {
        top: 0;
        left: 0;
        width: 45%; } }
  .about__photo {
    position: absolute;
    pointer-events: none; }
    .about__photo:first-child {
      top: 11%;
      left: -31%;
      width: 68%; }
    .about__photo:nth-child(2) {
      top: 9.5%;
      left: 25%;
      width: 23%; }

@media only screen and (max-width: 1179px) {
  .team {
    padding-top: 112px; } }

@media only screen and (max-width: 1023px) {
  .team {
    padding-top: 64px; } }

.team__head {
  margin-bottom: 80px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .team__head {
      margin-bottom: 48px; } }

.team__title {
  margin-bottom: 20px; }

.team__info {
  max-width: 545px;
  margin: 0 auto 40px;
  color: #353945; }
  body.dark .team__info {
    color: #E6E8EC; }

.team__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -12px -6px 0; }
  @media only screen and (max-width: 767px) {
    .team__nav {
      display: none; } }

.team .select {
  display: none; }
  @media only screen and (max-width: 767px) {
    .team .select {
      display: block; } }

.team__link {
  margin: 12px 6px 0;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  font-family: 'SFProDisplay-Bold';
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777E90;
  transition: all .2s; }
  .team__link:hover {
    color: #353945; }
    body.dark .team__link:hover {
      color: #E6E8EC; }
  .team__link.active {
    background: #353945;
    color: #FCFCFD; }
    body.dark .team__link.active {
      background: #FCFCFD;
      color: #23262F; }

.team__box {
  display: none; }

@media only screen and (max-width: 767px) {
  .team__wrap {
    position: relative;
    padding-bottom: 72px; } }

.team__slider {
  display: flex;
  flex-wrap: wrap;
  margin: -60px -16px 0; }
  @media only screen and (max-width: 767px) {
    .team__slider {
      position: static;
      display: block;
      margin: 0;
      visibility: hidden; }
      .team__slider.slick-initialized {
        visibility: visible; } }

.team__slide {
  flex: 0 0 calc(33.333% - 32px);
  width: calc(33.333% - 32px);
  margin: 60px 16px 0; }
  @media only screen and (max-width: 767px) {
    .team__slide {
      width: auto;
      margin: 0; } }

.team__item {
  display: block;
  position: relative;
  text-align: center;
  cursor: pointer;
  color: #23262F; }
  .team__item:hover .team__avatar {
    box-shadow: 0 0 0 2px #FF592C; }
    @media only screen and (max-width: 767px) {
      .team__item:hover .team__avatar {
        box-shadow: none; } }
  body.dark .team__item {
    color: #FCFCFD; }

.team__avatar {
  position: relative;
  width: 160px;
  margin: 0 auto 24px;
  border-radius: 16px;
  overflow: hidden;
  background: #E6E8EC;
  transition: box-shadow .2s; }
  body.dark .team__avatar {
    background: #353945; }
  .team__avatar:before {
    content: '';
    display: block;
    padding-bottom: 100%; }
  @media only screen and (max-width: 767px) {
    .team__avatar {
      width: 100%; } }
  .team__avatar img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; }

.team__man {
  margin-bottom: 4px;
  font-weight: 500; }

.team__position {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

@media only screen and (max-width: 474px) {
  .team .slick-list {
    margin: 0 -192px 0 -8px; } }

.team .slick-slide {
  padding: 0 8px; }

.team .slick-arrow {
  bottom: 0; }

.team .slick-prev {
  left: calc(50% - 34px); }

.team .slick-next {
  right: calc(50% - 34px); }

.review__center {
  max-width: 896px; }

.review__wrap {
  margin: 0 -12px;
  text-align: center; }

.review__slider {
  visibility: hidden;
  padding-bottom: 70px; }
  @media only screen and (max-width: 767px) {
    .review__slider {
      padding-bottom: 64px; } }
  .review__slider.slick-initialized {
    visibility: visible; }

.review__logo {
  display: inline-block; }

.review__title {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #E6E8EC;
  font-family: 'SFProDisplay-Bold';
  font-size: 40px;
  line-height: 1.2; }
  @media only screen and (max-width: 767px) {
    .review__title {
      font-size: 32px; } }
  body.dark .review__title {
    border-color: #353945; }

.review__author {
  margin-bottom: 4px;
  font-weight: 600; }

.review__position {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.review .slick-slide {
  padding: 0 12px; }

.review .slick-arrow {
  bottom: 0; }

.review .slick-prev {
  left: calc(50% - 34px); }

.review .slick-next {
  right: calc(50% - 34px); }

.quality__list {
  display: flex;
  margin: 0 -16px; }
  @media only screen and (max-width: 1023px) {
    .quality__list {
      flex-wrap: wrap;
      margin: -64px -16px 0; } }
  @media only screen and (max-width: 767px) {
    .quality__list {
      display: block;
      margin: 0; } }

.quality__item {
  position: relative;
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 0 16px; }
  @media only screen and (max-width: 1023px) {
    .quality__item {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px);
      margin: 64px 16px 0; } }
  @media only screen and (max-width: 767px) {
    .quality__item {
      width: 100%;
      margin: 0; }
      .quality__item:not(:last-child) {
        margin-bottom: 64px; } }

.quality__preview {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 40px;
  height: 40px;
  margin-bottom: 64px; }
  @media only screen and (max-width: 1023px) {
    .quality__preview {
      margin-bottom: 48px; } }
  @media only screen and (max-width: 767px) {
    .quality__preview {
      margin-bottom: 32px; } }
  .quality__preview img {
    max-width: 100%; }

.quality__counter {
  margin-bottom: 40px;
  padding-bottom: 40px;
  border-bottom: 1px solid #E6E8EC;
  font-family: 'SFProDisplay-Bold';
  font-size: 64px;
  line-height: 1;
  font-weight: 700; }
  body.dark .quality__counter {
    border-color: #353945; }

.quality__title {
  margin-bottom: 16px;
  font-weight: 600; }

.quality__content {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.advantages__head {
  margin-bottom: 80px;
  text-align: center; }

.advantages__title {
  margin-bottom: 20px; }

.advantages__info {
  max-width: 455px;
  margin: 0 auto;
  color: #353945; }
  @media only screen and (max-width: 1179px) {
    .advantages__info {
      max-width: 100%;
      color: #777E90; } }
  body.dark .advantages__info {
    color: #B1B5C3; }

.advantages__list {
  display: flex;
  margin: 0 -12px; }
  @media only screen and (max-width: 1023px) {
    .advantages__list {
      margin: 0 -40px;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch; }
      .advantages__list::-webkit-scrollbar {
        display: none; }
      .advantages__list:before, .advantages__list:after {
        content: "";
        flex-shrink: 0;
        width: 40px;
        height: 1px; } }

.advantages__item {
  flex: 0 0 calc(33.333% - 24px);
  width: calc(33.333% - 24px);
  margin: 0 12px;
  padding: 0 32px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .advantages__item {
      flex: 0 0 280px;
      margin: 0;
      padding: 0; } }
  @media only screen and (max-width: 767px) {
    .advantages__item {
      flex: 0 0 260px; } }
  @media only screen and (max-width: 1023px) {
    .advantages__item:not(:last-child) {
      margin-right: 32px; } }
  @media only screen and (max-width: 767px) {
    .advantages__item:not(:last-child) {
      margin-right: 8px; } }

.advantages__preview {
  height: 204px;
  margin-bottom: 36px; }
  .advantages__preview img {
    max-height: 100%; }

.advantages__subtitle {
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600; }

.workouts {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 1096px;
  padding: 100px 0;
  overflow: hidden; }
  @media only screen and (max-width: 1339px) {
    .workouts {
      min-height: 960px; } }
  @media only screen and (max-width: 1179px) {
    .workouts {
      min-height: 840px; } }
  @media only screen and (max-width: 767px) {
    .workouts {
      min-height: auto;
      padding: 64px 0; } }
  .workouts__wrap {
    position: relative;
    z-index: 3;
    max-width: 450px; }
    @media only screen and (max-width: 1179px) {
      .workouts__wrap {
        max-width: 420px; } }
  .workouts__title {
    margin-bottom: 32px; }
  .workouts__info {
    margin-bottom: 32px;
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #777E90; }
  .workouts__list {
    margin-bottom: 48px; }
    .workouts__list li {
      position: relative;
      padding-left: 36px;
      font-weight: 600;
      color: #777E90; }
      .workouts__list li:before {
        content: "";
        position: absolute;
        top: -1px;
        left: 0;
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 12L10 16L18 8' stroke='%2345B36B' stroke-width='2' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A") no-repeat 50% 50%/100% auto; }
      .workouts__list li:not(:last-child) {
        margin-bottom: 16px; }
  .workouts__btns {
    display: flex; }
  @media only screen and (max-width: 767px) {
    .workouts__button {
      padding: 0 20px; } }
  .workouts__button:not(:last-child) {
    margin-right: 12px; }
  .workouts__gallery {
    position: absolute;
    top: 50%;
    right: calc(50% - 663px);
    width: 744px;
    transform: translateY(-50%);
    pointer-events: none; }
    @media only screen and (max-width: 1339px) {
      .workouts__gallery {
        right: calc(50% - 570px);
        width: 640px; } }
    @media only screen and (max-width: 1179px) {
      .workouts__gallery {
        width: 550px; } }
    @media only screen and (max-width: 767px) {
      .workouts__gallery {
        position: relative;
        top: auto;
        right: auto;
        left: -4%;
        width: 380px;
        margin: 0 auto 42px;
        transform: translateY(0); } }
  .workouts__preview:first-child {
    position: relative;
    z-index: 1; }
  .workouts__preview:nth-child(2) {
    top: 10%;
    left: 47%;
    z-index: 2;
    width: 13.5%; }
  .workouts__preview:nth-child(3) {
    top: 32%;
    right: 2.5%;
    z-index: 2;
    width: 14.5%; }
  .workouts__preview:not(:first-child) {
    position: absolute; }
  .workouts__preview img {
    width: 100%; }

.offers {
  text-align: center; }
  .offers .stage {
    margin-bottom: 16px; }
  .offers__title {
    max-width: 650px;
    margin: 0 auto 32px; }
    @media only screen and (max-width: 1023px) {
      .offers__title {
        max-width: 550px; } }
  .offers__text {
    max-width: 450px;
    margin: 0 auto 48px;
    color: #777E90; }

.theme {
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-size: 0; }
  @media only screen and (max-width: 767px) {
    .theme {
      position: absolute;
      top: 60px;
      right: 28px; } }
  .theme__input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0; }
    .theme__input:checked + .theme__inner {
      border-color: #23262F; }
      .theme__input:checked + .theme__inner .theme__box {
        background: rgba(35, 38, 47, 0.3); }
        .theme__input:checked + .theme__inner .theme__box:before {
          width: 8px;
          height: 24px;
          transform: translate(50px, -50%);
          border-radius: 4px; }
    .theme__input:checked + .theme__inner .theme__icon .icon {
      fill: #FCFCFD; }
      .theme__input:checked + .theme__inner .theme__icon .icon:first-child {
        opacity: 0; }
      .theme__input:checked + .theme__inner .theme__icon .icon:nth-child(2) {
        opacity: 1; }
  .theme__inner {
    position: relative;
    display: inline-block;
    border: 4px solid #E6E8EC;
    border-radius: 24px;
    transition: all .2s; }
  .theme__box {
    position: relative;
    display: block;
    width: 72px;
    height: 32px;
    box-shadow: inset 0 0 0 1px rgba(119, 126, 144, 0.4);
    background: #FCFCFD;
    border-radius: 16px;
    transition: all .2s; }
    .theme__box:before {
      content: "";
      position: absolute;
      top: 50%;
      left: 4px;
      transform: translateY(-50%);
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background: #3772FF;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      transition: all .2s; }
  .theme__icon .icon {
    position: absolute;
    top: 50%;
    width: 24px;
    height: 24px;
    transform: translateY(-50%);
    fill: #B1B5C3;
    transition: all .2s; }
    .theme__icon .icon:first-child {
      right: 5px; }
    .theme__icon .icon:nth-child(2) {
      left: 5px;
      opacity: 0; }

.subscription {
  position: relative; }
  .subscription__input {
    width: 100%;
    height: 48px;
    padding: 0 48px 0 14px;
    border-radius: 24px;
    background: none;
    border: 2px solid #E6E8EC;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    line-height: 1.71429;
    color: #23262F;
    transition: border-color .2s; }
    .subscription__input:focus {
      border-color: #777E90; }
    .subscription__input::placeholder {
      color: #777E90; }
    body.dark .subscription__input {
      border-color: #353945;
      color: #FCFCFD; }
      body.dark .subscription__input:focus {
        border-color: #777E90; }
  .subscription__btn {
    position: absolute;
    top: 8px;
    right: 8px;
    bottom: 8px;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: #FF592C;
    transition: background .2s; }
    .subscription__btn .icon {
      width: 14px;
      height: 14px;
      fill: #FCFCFD; }
    .subscription__btn:hover {
      background: #f83500; }

.footer {
  border-top: 1px solid rgba(119, 126, 144, 0.3); }
  body.dark .footer {
    border-color: #353945; }
  .footer__center {
    display: flex; }
    @media only screen and (max-width: 767px) {
      .footer__center {
        display: block; } }
  @media only screen and (max-width: 767px) {
    .footer__body {
      position: relative;
      padding-top: 32px; } }
  .footer__col {
    flex: 0 0 30.5%;
    padding: 80px 86px 64px; }
    @media only screen and (max-width: 1339px) {
      .footer__col {
        padding: 80px 64px 64px; } }
    @media only screen and (max-width: 1179px) {
      .footer__col {
        flex: 0 0 34%; } }
    @media only screen and (max-width: 1023px) {
      .footer__col {
        padding: 64px 32px 48px; } }
    @media only screen and (max-width: 767px) {
      .footer__col {
        padding: 32px 0;
        border-bottom: 1px solid #E6E8EC; }
        body.dark .footer__col {
          border-color: #353945; } }
    .footer__col:first-child {
      display: flex;
      flex: 0 0 39%;
      padding-left: 0; }
      @media only screen and (max-width: 1179px) {
        .footer__col:first-child {
          flex: 0 0 32%; } }
      @media only screen and (max-width: 767px) {
        .footer__col:first-child {
          display: block; } }
    .footer__col:nth-child(3) {
      padding-right: 0; }
    .footer__col:nth-child(2), .footer__col:nth-child(3) {
      border-left: 1px solid #E6E8EC; }
      @media only screen and (max-width: 767px) {
        .footer__col:nth-child(2), .footer__col:nth-child(3) {
          border-left: none; } }
      body.dark .footer__col:nth-child(2), body.dark .footer__col:nth-child(3) {
        border-color: #353945; }
  .footer__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 112px; }
    @media only screen and (max-width: 1339px) {
      .footer__box {
        margin-right: 76px; } }
    @media only screen and (max-width: 1179px) {
      .footer__box {
        margin-right: 40px; } }
    @media only screen and (max-width: 1023px) {
      .footer__box {
        margin-right: 24px; } }
    @media only screen and (max-width: 767px) {
      .footer__box {
        margin: 0 0 40px; } }
  .footer__logo {
    display: inline-block;
    width: 32px;
    margin-bottom: auto;
    overflow: hidden; }
    @media only screen and (max-width: 767px) {
      .footer__logo {
        width: auto; } }
    .footer__logo img {
      width: 166px; }
  .footer__item .footer__category {
    display: none; }
    @media only screen and (max-width: 767px) {
      .footer__item .footer__category {
        display: flex;
        align-items: center;
        margin-bottom: 0; } }
    .footer__item .footer__category .icon {
      width: 9px;
      height: 9px;
      margin-left: auto;
      fill: #777E90;
      transition: transform .2s; }
    @media only screen and (max-width: 767px) {
      .footer__item .footer__category.active .icon {
        transform: rotate(180deg); } }
  .footer__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    @media only screen and (min-width: 768px) {
      .footer__menu {
        display: flex !important; } }
    @media only screen and (max-width: 767px) {
      .footer__menu {
        display: none;
        padding-top: 40px; } }
  .footer__link {
    font-family: 'SFProDisplay-Bold';
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: color .2s; }
    @media only screen and (max-width: 767px) {
      .footer__link {
        display: block; } }
    .footer__link:hover {
      color: #23262F; }
      body.dark .footer__link:hover {
        color: #FCFCFD; }
    .footer__link:not(:last-child) {
      margin-bottom: 24px; }
  .footer__category {
    margin-bottom: 41px;
    font-size: 12px;
    line-height: 1;
    font-weight: 700;
    text-transform: uppercase; }
  .footer__info {
    font-size: 14px;
    line-height: 1.71429; }
    .footer__info p:not(:last-child) {
      margin-bottom: 8px; }
    .footer__info:not(:last-child) {
      margin-bottom: 24px; }
  .footer__bottom {
    padding: 25px 0 20px;
    border-top: 1px solid #E6E8EC; }
    @media only screen and (max-width: 767px) {
      .footer__bottom {
        padding: 56px 0 48px;
        border: none; } }
    body.dark .footer__bottom {
      border-color: #353945; }
  .footer__copyright {
    margin-right: auto;
    font-size: 12px;
    line-height: 1.66667;
    color: #777E90; }
    @media only screen and (max-width: 767px) {
      .footer__copyright {
        margin: 0 0 24px; } }
  .footer__socials {
    display: flex; }
  .footer__social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px; }
    .footer__social .icon {
      width: 16px;
      height: 16px;
      fill: #777E90;
      transition: fill .2s; }
      .footer__social .icon-twitter {
        width: 18px;
        height: 18px; }
      .footer__social .icon-behance {
        width: 20px;
        height: 20px; }
    .footer__social:hover .icon {
      fill: #23262F; }
      body.dark .footer__social:hover .icon {
        fill: #FCFCFD; }
    .footer__social:not(:last-child) {
      margin-right: 24px; }

body.dark .pricing {
  background: none; }

.pricing .stage {
  margin-bottom: 16px;
  text-align: center; }

.pricing__title {
  max-width: 550px;
  margin: 0 auto 80px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .pricing__title {
      margin-bottom: 64px; } }

.pricing__row {
  display: flex; }
  @media only screen and (max-width: 767px) {
    .pricing__row {
      display: block; } }

.pricing__col {
  flex: 1 1; }
  @media only screen and (max-width: 767px) {
    .pricing__col {
      padding: 48px 32px 64px;
      background: #FCFCFD;
      border-radius: 24px; }
      body.dark .pricing__col {
        background: #23262F; } }
  .pricing__col:first-child {
    flex: 0 0 350px; }
    @media only screen and (max-width: 1179px) {
      .pricing__col:first-child {
        flex: 0 0 220px; } }
    @media only screen and (max-width: 1023px) {
      .pricing__col:first-child {
        flex: 0 0 130px; } }
    @media only screen and (max-width: 767px) {
      .pricing__col:first-child {
        display: none; } }
  .pricing__col:nth-child(3) {
    border-radius: 24px;
    background: #FCFCFD; }
    body.dark .pricing__col:nth-child(3) {
      background: #23262F; }
      body.dark .pricing__col:nth-child(3) .pricing__parameter {
        border-color: rgba(119, 126, 144, 0.3); }
  .pricing__col:not(:first-child) {
    padding-bottom: 54px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .pricing__col:not(:first-child) {
        text-align: left; } }
    .pricing__col:not(:first-child) .pricing__body {
      padding-top: 28px; }
    .pricing__col:not(:first-child) .pricing__parameter {
      justify-content: center;
      text-align: center; }
    .pricing__col:not(:first-child) .pricing__label {
      display: none; }
      @media only screen and (max-width: 767px) {
        .pricing__col:not(:first-child) .pricing__label {
          display: block; } }
  @media only screen and (max-width: 767px) {
    .pricing__col:not(:last-child) {
      margin-bottom: 32px; } }

.pricing__head {
  min-height: 256px;
  padding: 48px 32px 44px; }
  @media only screen and (max-width: 1023px) {
    .pricing__head {
      padding: 48px 16px 44px; } }
  @media only screen and (max-width: 767px) {
    .pricing__head {
      min-height: auto;
      padding: 0; } }

.pricing__more {
  display: none; }
  @media only screen and (max-width: 767px) {
    .pricing__more {
      display: flex;
      align-items: center;
      font-size: 12px;
      line-height: 1.66667;
      font-weight: 600; }
      .pricing__more.active .icon {
        transform: rotate(180deg); } }
  .pricing__more .icon {
    width: 10px;
    height: 10px;
    margin-left: 12px;
    fill: #777E90;
    transition: transform .2s; }

.pricing__package {
  margin-bottom: 8px;
  font-weight: 600; }

.pricing__description {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.pricing__cost {
  position: relative;
  display: inline-block;
  margin-top: 32px; }
  @media only screen and (max-width: 767px) {
    .pricing__cost {
      margin-left: 15px; } }

.pricing__price {
  font-family: 'SFProDisplay-Bold';
  font-size: 48px;
  line-height: 56px;
  letter-spacing: -.02em; }

.pricing__sign {
  position: absolute;
  top: 0;
  right: calc(100% + 4px);
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -.01em; }

.pricing__note {
  font-size: 12px;
  line-height: 1.66667;
  color: #777E90; }

@media only screen and (min-width: 768px) {
  .pricing__list {
    display: block !important; } }

@media only screen and (max-width: 767px) {
  .pricing__list {
    display: none;
    padding-top: 24px; }
    .pricing__list.visible {
      display: block; } }

.pricing__category {
  margin-bottom: 16px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #777E90; }

.pricing__parameter {
  display: flex;
  align-items: center;
  height: 64px;
  border-bottom: 1px solid #E6E8EC; }
  body.dark .pricing__parameter {
    border-color: #23262F; }

.pricing__label {
  margin-right: auto;
  font-weight: 600; }

.pricing__hint {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 20px;
  border-radius: 50%;
  border: 2px solid #B1B5C3;
  cursor: pointer; }
  @media only screen and (max-width: 1023px) {
    .pricing__hint {
      display: none; } }
  .pricing__hint .icon {
    width: 10px;
    height: 10px;
    fill: #B1B5C3; }
  .pricing__hint:hover .pricing__tooltip {
    visibility: visible;
    opacity: 1; }

.pricing__tooltip {
  position: absolute;
  top: 50%;
  left: calc(100% + 20px);
  min-width: 260px;
  padding: 6px 12px;
  transform: translateY(-50%);
  border-radius: 4px;
  background: #FCFCFD;
  box-shadow: 0 4px 12px rgba(35, 38, 47, 0.1);
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  transition: all .2s; }
  .pricing__tooltip:before {
    content: "";
    position: absolute;
    right: 100%;
    top: 50%;
    transform: translateY(-50%);
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent #FCFCFD transparent transparent; }
  body.dark .pricing__tooltip {
    background: #353945;
    box-shadow: 0 4px 12px rgba(20, 20, 22, 0.1); }
    body.dark .pricing__tooltip:before {
      width: 0px;
      height: 0px;
      border-style: solid;
      border-width: 6px 6px 6px 0;
      border-color: transparent #353945 transparent transparent; }

.pricing__check {
  justify-content: center; }
  .pricing__check .icon {
    width: 14px;
    height: 14px;
    fill: #45B26B; }

.pricing__minus {
  padding: 0 2px;
  font-size: 18px;
  font-weight: 600;
  color: #777E90; }

.pricing__button {
  margin-top: 54px; }

.comment__wrapper {
  position: relative; }
  @media only screen and (max-width: 767px) {
    .comment__wrapper {
      padding-bottom: 64px; } }

.comment__wrap {
  margin: 0 -16px;
  text-align: center; }

.comment__head {
  max-width: calc(50% - 16px);
  margin: 0 0 80px auto; }
  @media only screen and (max-width: 1179px) {
    .comment__head {
      max-width: calc(66.66% - 16px); } }
  @media only screen and (max-width: 767px) {
    .comment__head {
      max-width: 100%;
      margin: 0 0 48px; } }

.comment__title {
  margin-bottom: 20px; }

.comment__info {
  color: #353945; }
  body.dark .comment__info {
    color: #E6E8EC; }

.comment__slider {
  position: static;
  visibility: hidden; }
  .comment__slider.slick-initialized {
    visibility: visible; }

.comment__item {
  display: block;
  text-align: center;
  color: #23262F; }
  body.dark .comment__item {
    color: #FCFCFD; }

.comment__preview {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 222px;
  margin-bottom: 24px;
  background: #E6E8EC;
  border-radius: 8px; }
  body.dark .comment__preview {
    background: #353945; }
  .comment__preview img {
    max-width: 120px;
    max-height: 120px; }
  .comment__preview:before, .comment__preview:after {
    content: "";
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: all .2s; }
  .comment__preview:before {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #23262F;
    border-radius: 8px; }
    body.dark .comment__preview:before {
      background: #FCFCFD; }
  .comment__preview:after {
    top: 50%;
    left: 50%;
    width: 48px;
    height: 48px;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: #FCFCFD url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath fill-rule='evenodd' d='M6.394.843c.271-.25.692-.233.942.038L9.49 3.214c.236.255.236.649 0 .904L7.336 6.452c-.25.271-.672.287-.942.038s-.287-.672-.038-.942l1.121-1.215H1c-.368 0-.667-.298-.667-.667S.632 3 1 3h6.477L6.356 1.786c-.25-.271-.233-.692.038-.942z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50%/10px auto; }
    body.dark .comment__preview:after {
      background-color: #23262F;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' fill='none' xmlns:v='https://vecta.io/nano'%3E%3Cpath fill-rule='evenodd' d='M6.394.843c.271-.25.692-.233.942.038L9.49 3.214c.236.255.236.649 0 .904L7.336 6.452c-.25.271-.672.287-.942.038s-.287-.672-.038-.942l1.121-1.215H1c-.368 0-.667-.298-.667-.667S.632 3 1 3h6.477L6.356 1.786c-.25-.271-.233-.692.038-.942z' fill='%23777E90'/%3E%3C/svg%3E"); }

.comment__item:hover .comment__preview:before, .comment__item:hover .comment__preview:after {
  opacity: 1;
  visibility: visible; }

.comment__subtitle {
  margin-bottom: 8px;
  font-weight: 600;
  color: #23262F; }
  body.dark .comment__subtitle {
    color: #FCFCFD; }

.comment__content {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.comment .slick-slide {
  padding: 0 16px;
  opacity: 0;
  transition: opacity .4s; }
  .comment .slick-slide.slick-active {
    opacity: 1; }

.comment .slick-arrow {
  top: 0; }
  @media only screen and (max-width: 767px) {
    .comment .slick-arrow {
      top: auto;
      bottom: 0; } }

.comment .slick-prev {
  left: 0; }
  @media only screen and (max-width: 767px) {
    .comment .slick-prev {
      left: calc(50% - 34px); } }

.comment .slick-next {
  left: 44px; }
  @media only screen and (max-width: 767px) {
    .comment .slick-next {
      left: auto;
      right: calc(50% - 34px); } }

.faq__top {
  margin-bottom: 80px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .faq__top {
      margin-bottom: 48px; } }

.faq__title {
  margin-bottom: 20px; }

.faq__info {
  max-width: 650px;
  margin: 0 auto 80px;
  color: #353945; }
  @media only screen and (max-width: 767px) {
    .faq__info {
      margin-bottom: 48px; } }
  body.dark .faq__info {
    color: #E6E8EC; }
  .faq__info a {
    font-weight: 600;
    color: #FF592C;
    transition: opacity .2s; }
    .faq__info a:hover {
      opacity: .8; }

.faq__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -12px -6px 0; }
  @media only screen and (max-width: 767px) {
    .faq__nav {
      display: none; } }

.faq__link {
  margin: 12px 6px 0;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  font-family: 'SFProDisplay-Bold';
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777E90;
  transition: all .2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  .faq__link:hover {
    color: #353945; }
    body.dark .faq__link:hover {
      color: #FCFCFD; }
  .faq__link.active {
    background: #353945;
    color: #FCFCFD; }
    body.dark .faq__link.active {
      background: #FCFCFD;
      color: #23262F; }

.faq .select {
  display: none;
  text-align: left; }
  @media only screen and (max-width: 767px) {
    .faq .select {
      display: block; } }

.faq__box {
  display: none; }
  .faq__box:first-child {
    display: block; }

.faq__list {
  margin-bottom: -32px; }

.faq__item {
  border-top: 1px solid #E6E8EC; }
  .faq__item.active .faq__arrow .icon {
    transform: rotate(180deg); }
  body.dark .faq__item {
    border-color: #353945; }

.faq__head {
  position: relative;
  padding: 36px 40px 36px 0;
  font-weight: 600;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media only screen and (max-width: 767px) {
    .faq__head {
      padding: 32px 40px 32px 0; } }

.faq__arrow {
  position: absolute;
  top: 50%;
  right: 0;
  width: 24px;
  height: 24px;
  transform: translateY(-50%); }
  .faq__arrow .icon {
    width: 10px;
    height: 10px;
    fill: #777E90;
    transition: transform .2s; }

.faq__body {
  display: none;
  padding: 8px 0 32px; }

.faq__row {
  display: flex;
  justify-content: space-between; }
  @media only screen and (max-width: 1179px) {
    .faq__row {
      margin: 0 -16px; } }
  @media only screen and (max-width: 767px) {
    .faq__row {
      display: block;
      margin: 0; } }

.faq__col {
  flex: 0 0 450px; }
  @media only screen and (max-width: 1179px) {
    .faq__col {
      flex: 0 0 calc(50% - 32px);
      width: calc(50% - 32px);
      margin: 0 16px; } }
  @media only screen and (max-width: 767px) {
    .faq__col {
      width: 100%;
      margin: 0; }
      .faq__col:not(:last-child) {
        margin-bottom: 32px; } }

.faq__preview {
  position: relative; }
  .faq__preview img {
    width: 100%;
    border-radius: 16px; }

.faq__subtitle {
  margin-bottom: 20px;
  font-weight: 600; }

.faq__content {
  margin-bottom: 24px;
  color: #777E90; }
  .faq__content p:not(:last-child) {
    margin-bottom: 24px; }

.testimonials__nav {
  display: flex;
  justify-content: center;
  margin: 80px -16px 0; }
  @media only screen and (max-width: 1179px) {
    .testimonials__nav {
      margin: 80px -8px 0; } }
  @media only screen and (max-width: 1023px) {
    .testimonials__nav {
      justify-content: flex-start;
      margin: 80px -40px 0;
      overflow: auto;
      overflow-x: auto;
      -ms-overflow-style: none;
      scrollbar-width: none;
      -webkit-overflow-scrolling: touch; }
      .testimonials__nav::-webkit-scrollbar {
        display: none; }
      .testimonials__nav:before, .testimonials__nav:after {
        content: "";
        flex-shrink: 0;
        width: 40px;
        height: 1px; } }
  @media only screen and (max-width: 767px) {
    .testimonials__nav {
      margin-top: 48px; } }

.testimonials__link {
  position: relative;
  display: flex;
  align-items: center;
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 0 16px;
  padding-bottom: 26px;
  cursor: pointer;
  color: #23262F;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media only screen and (max-width: 1179px) {
    .testimonials__link {
      flex: 0 0 calc(25% - 16px);
      width: calc(25% - 16px);
      margin: 0 8px; } }
  @media only screen and (max-width: 1023px) {
    .testimonials__link {
      flex: 0 0 256px;
      width: 256px;
      margin: 0; } }
  .testimonials__link:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    border-radius: 2px;
    background: #23262F;
    opacity: 0;
    transition: opacity .2s; }
    body.dark .testimonials__link:after {
      background: #FCFCFD; }
  .testimonials__link:hover:after, .testimonials__link.active:after {
    opacity: 1; }
  .testimonials__link:not(:last-child) {
    margin-right: 32px; }
  body.dark .testimonials__link {
    color: #FCFCFD; }

.testimonials__avatar {
  flex-shrink: 48px;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  background: #E6E8EC;
  border-radius: 50%;
  overflow: hidden; }
  .testimonials__avatar img {
    width: 100%;
    height: 100%; }

.testimonials__man {
  margin-bottom: 4px;
  font-size: 14px;
  line-height: 1.71429;
  font-weight: 600; }

.testimonials__position {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.testimonials__item {
  display: none; }
  .testimonials__item:first-child {
    display: block; }

.testimonials__row {
  display: flex;
  margin: 0 -16px; }
  @media only screen and (max-width: 767px) {
    .testimonials__row {
      display: block;
      margin: 0; } }

.testimonials__col {
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 0 16px; }
  @media only screen and (max-width: 767px) {
    .testimonials__col {
      width: 100%;
      margin: 0; }
      .testimonials__col:not(:last-child) {
        margin-bottom: 48px; } }

.testimonials__logo {
  margin-bottom: 48px; }
  @media only screen and (max-width: 1023px) {
    .testimonials__logo {
      margin-bottom: 24px; } }
  .testimonials__logo img {
    max-width: 166px; }

.testimonials__comment {
  max-width: 450px;
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -.01em; }
  @media only screen and (max-width: 1023px) {
    .testimonials__comment {
      font-size: 20px; } }
  @media only screen and (max-width: 767px) {
    .testimonials__comment {
      font-size: 24px; } }

.testimonials__details {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 48px;
  font-size: 14px;
  line-height: 1.71429; }
  @media only screen and (max-width: 767px) {
    .testimonials__details {
      margin-bottom: 32px; } }

.testimonials__icon {
  margin-right: 12px; }

.testimonials__author {
  margin-right: 24px;
  font-weight: 600; }

.testimonials__description {
  color: #777E90; }
  @media only screen and (max-width: 1023px) {
    .testimonials__description {
      margin-left: 36px; } }

.testimonials__preview {
  position: relative; }
  .testimonials__preview img {
    width: 100%;
    border-radius: 16px; }
    @media only screen and (max-width: 767px) {
      .testimonials__preview img {
        border-radius: 12px; } }

.program__wrap {
  display: flex;
  align-items: center;
  padding: 48px 76px 64px;
  background: #FCFCFD;
  box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
  border-radius: 24px; }
  @media only screen and (max-width: 1179px) {
    .program__wrap {
      padding: 48px 24px; } }
  @media only screen and (max-width: 767px) {
    .program__wrap {
      display: block;
      border-radius: 16px; } }
  body.dark .program__wrap {
    background: #141416; }

.program__col:first-child {
  flex: 0 0 372px;
  padding-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .program__col:first-child {
      margin: 0 -10px 16px;
      padding-bottom: 0; } }

.program__col:nth-child(2) {
  flex-grow: 1;
  padding-left: 20px;
  text-align: center; }
  @media only screen and (max-width: 767px) {
    .program__col:nth-child(2) {
      padding-left: 0; } }

.program .stage-small {
  margin-bottom: 24px;
  padding-left: 24px; }
  @media only screen and (max-width: 767px) {
    .program .stage-small {
      margin-bottom: 16px;
      padding-left: 10px; } }

.program__item {
  display: flex;
  align-items: center;
  padding: 20px;
  border-radius: 16px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -.01em;
  font-weight: 600;
  color: #23262F;
  transition: background .2s; }
  @media only screen and (max-width: 767px) {
    .program__item {
      padding: 10px;
      font-size: 18px; } }
  .program__item:hover {
    background: #F4F5F6; }
  body.dark .program__item {
    background: #23262F;
    color: #FCFCFD; }
    body.dark .program__item:hover {
      background: #353945; }
  .program__item:not(:last-child) {
    margin-bottom: 16px; }
    @media only screen and (max-width: 767px) {
      .program__item:not(:last-child) {
        margin-bottom: 0; } }
  @media only screen and (max-width: 767px) {
    body.dark .program__item:not(:last-child) {
      margin-bottom: 8px; } }

.program__arrow {
  margin-left: auto;
  padding-left: 20px; }
  .program__arrow .icon {
    width: 14px;
    height: 14px;
    fill: #777E90; }

.program__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  border-radius: 12px; }
  @media only screen and (max-width: 767px) {
    .program__icon {
      margin-right: 12px; } }
  .program__icon img {
    max-width: 24px;
    max-height: 24px; }

.program__preview {
  margin-bottom: 42px; }
  @media only screen and (max-width: 767px) {
    .program__preview {
      margin-bottom: 32px; } }
  .program__preview img {
    max-width: 400px; }
    @media only screen and (max-width: 1179px) {
      .program__preview img {
        max-width: 240px; } }

.program__info {
  max-width: 380px;
  margin: 0 auto 24px;
  color: #777E90; }

@media only screen and (max-width: 767px) {
  .lifestyle {
    padding-top: 64px; } }

.lifestyle__head {
  max-width: 545px;
  margin-bottom: 80px; }
  @media only screen and (max-width: 767px) {
    .lifestyle__head {
      margin-bottom: 48px; } }

.lifestyle__stage {
  margin-bottom: 32px; }

.lifestyle__title {
  margin-bottom: 20px; }

.lifestyle__info {
  margin-bottom: 24px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -.01em;
  color: #777E90; }

@media only screen and (max-width: 767px) {
  .lifestyle__button {
    width: 100%; } }

@media only screen and (max-width: 767px) {
  .lifestyle__wrap {
    margin: 0 -12px; } }

@media only screen and (max-width: 767px) {
  .lifestyle__slider {
    padding-bottom: 70px;
    visibility: hidden; }
    .lifestyle__slider.slick-initialized {
      visibility: visible; } }

.lifestyle__row {
  display: flex;
  align-items: center;
  margin: 0 -16px; }
  @media only screen and (max-width: 767px) {
    .lifestyle__row {
      display: flex !important;
      flex-direction: column-reverse;
      margin: 0; } }
  .lifestyle__row:not(:last-child) {
    margin-bottom: 112px; }
    @media only screen and (max-width: 1023px) {
      .lifestyle__row:not(:last-child) {
        margin-bottom: 80px; } }
    @media only screen and (max-width: 767px) {
      .lifestyle__row:not(:last-child) {
        margin-bottom: 0; } }

.lifestyle__col {
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 0 16px; }
  @media only screen and (max-width: 767px) {
    .lifestyle__col {
      flex: 0 0 100%;
      width: 100%; } }
  .lifestyle__col img {
    width: 100%;
    border-radius: 24px; }
  .lifestyle__col:first-child {
    padding-top: 32px; }
    @media only screen and (max-width: 1179px) {
      .lifestyle__col:first-child {
        padding-top: 0; } }
    @media only screen and (max-width: 767px) {
      .lifestyle__col:first-child {
        margin-top: 48px; } }

.lifestyle__details {
  max-width: 260px; }
  @media only screen and (max-width: 767px) {
    .lifestyle__details {
      max-width: 100%; } }

.lifestyle__number {
  margin-bottom: 24px;
  padding-bottom: 26px;
  border-bottom: 2px solid #E6E8EC;
  font-family: 'SFProDisplay-Bold';
  font-size: 32px;
  line-height: 1.25; }
  body.dark .lifestyle__number {
    border-color: #353945; }

.lifestyle__category {
  margin-bottom: 16px;
  font-weight: 600; }

.lifestyle__content {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.lifestyle .slick-slide {
  padding: 0 12px; }

.lifestyle .slick-arrow {
  bottom: 0; }

.lifestyle .slick-prev {
  left: 12px; }

.lifestyle .slick-next {
  left: 56px; }

.platform__title {
  margin-bottom: 40px; }
  @media only screen and (max-width: 767px) {
    .platform__title {
      text-align: center; } }

.platform__list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -16px;
  border-width: 2px 0 1px;
  border-style: solid;
  border-color: #E6E8EC; }
  @media only screen and (max-width: 1023px) {
    .platform__list {
      display: block;
      margin: 0;
      padding: 48px 0; } }
  @media only screen and (max-width: 767px) {
    .platform__list {
      padding: 0;
      border: none; } }
  body.dark .platform__list {
    border-color: #353945; }

.platform__item {
  display: flex;
  align-items: center;
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 80px 16px -1px;
  padding-bottom: 48px;
  border-bottom: 1px solid #E6E8EC;
  cursor: pointer; }
  @media only screen and (max-width: 1339px) {
    .platform__item {
      cursor: default; } }
  @media only screen and (max-width: 1023px) {
    .platform__item {
      width: 100%;
      margin: 0;
      padding: 0;
      border: none; } }
  @media only screen and (max-width: 767px) {
    .platform__item {
      display: block;
      text-align: center; } }
  body.dark .platform__item {
    border-color: #353945; }
  @media only screen and (max-width: 1023px) {
    .platform__item:not(:last-child) {
      margin-bottom: 48px; } }
  @media only screen and (max-width: 767px) {
    .platform__item:not(:last-child) {
      margin-bottom: 40px; } }
  .platform__item .platform__button {
    position: absolute;
    top: calc(100% + 24px);
    left: 0;
    visibility: hidden;
    opacity: 0; }
    @media only screen and (max-width: 1339px) {
      .platform__item .platform__button {
        position: static;
        margin-top: 24px;
        visibility: visible;
        opacity: 1; } }
    @media only screen and (max-width: 767px) {
      .platform__item .platform__button {
        margin-top: 16px; } }
  .platform__item:hover .platform__details {
    transform: translateY(-33px); }
    @media only screen and (max-width: 1339px) {
      .platform__item:hover .platform__details {
        transform: translateY(0); } }
  .platform__item:hover .platform__button {
    visibility: visible;
    opacity: 1; }

.platform__preview {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  margin-right: 32px;
  border-radius: 24px; }
  @media only screen and (max-width: 767px) {
    .platform__preview {
      margin: 0 auto 16px; } }
  .platform__preview img {
    max-width: 40px; }

.platform__details {
  position: relative;
  transition: transform .2s; }

.platform__status {
  margin-bottom: 12px; }
  @media only screen and (max-width: 767px) {
    .platform__status {
      line-height: 22px; } }

.platform__subtitle {
  margin-bottom: 4px;
  font-size: 24px;
  line-height: 1.33333;
  font-weight: 600; }

.platform__description {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.platform__btns {
  margin-top: 80px;
  text-align: center; }
  @media only screen and (max-width: 1023px) {
    .platform__btns {
      margin-top: 64px; } }
  @media only screen and (max-width: 767px) {
    .platform__btns {
      margin-top: 48px; } }

.community__center {
  text-align: center; }

.community__preview {
  margin-bottom: 66px; }
  @media only screen and (max-width: 767px) {
    .community__preview {
      margin-bottom: 48px; } }
  .community__preview img {
    width: 545px; }
    @media only screen and (max-width: 1179px) {
      .community__preview img {
        width: 450px; } }
    @media only screen and (max-width: 639px) {
      .community__preview img {
        width: 100%; } }

.community__title {
  margin-bottom: 20px; }

.community__text {
  max-width: 455px;
  margin: 0 auto 80px;
  color: #777E90; }
  @media only screen and (max-width: 1179px) {
    .community__text {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .community__text {
      margin-bottom: 48px; } }

.community .subscription {
  max-width: 355px;
  margin: 0 auto 16px; }

.community__note {
  font-size: 12px;
  line-height: 1.66667;
  color: #B1B5C3; }
  @media only screen and (max-width: 767px) {
    .community__note {
      max-width: 240px;
      margin: 0 auto; } }
  .community__note a {
    font-weight: 600;
    color: #FF592C;
    transition: opacity .2s; }
    .community__note a:hover {
      opacity: .8; }

.history {
  margin-bottom: 72px; }
  @media only screen and (max-width: 1179px) {
    .history {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .history {
      margin-bottom: 32px; } }
  .history__slider {
    visibility: hidden; }
    .history__slider.slick-initialized {
      visibility: visible; }
  .history__item {
    display: flex;
    border-radius: 20px;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    border: 1px solid #E6E8EC; }
    @media only screen and (max-width: 767px) {
      .history__item {
        display: block;
        border-radius: 24px; } }
    body.dark .history__item {
      border-color: #353945;
      background: #23262F; }
  .history__preview, .history__details {
    flex: 0 0 50%; }
  .history__preview {
    min-height: 516px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 20px 0 0 20px; }
    @media only screen and (max-width: 767px) {
      .history__preview {
        min-height: 232px;
        border-radius: 24px 24px 0 0; } }
  .history__details {
    padding: 64px 96px; }
    @media only screen and (max-width: 1339px) {
      .history__details {
        padding: 64px; } }
    @media only screen and (max-width: 1179px) {
      .history__details {
        padding: 56px; } }
    @media only screen and (max-width: 1023px) {
      .history__details {
        padding: 48px 32px; } }
    @media only screen and (max-width: 767px) {
      .history__details {
        padding: 40px 16px; } }
  .history__status {
    margin-bottom: 20px;
    padding: 0 12px; }
  .history__title {
    margin-bottom: 20px;
    font-family: 'SFProDisplay-Bold';
    font-size: 40px;
    line-height: 1.2;
    letter-spacing: -.01em; }
    @media only screen and (max-width: 1023px) {
      .history__title {
        font-size: 32px; } }
    @media only screen and (max-width: 767px) {
      .history__title {
        margin-bottom: 16px;
        font-size: 24px;
        letter-spacing: 0; } }
  .history__content {
    margin-bottom: 20px;
    color: #777E90; }
    @media only screen and (max-width: 767px) {
      .history__content {
        margin-bottom: 16px; } }
  .history .slick-list {
    overflow: visible; }
  .history .slick-slide {
    opacity: 0;
    transition: opacity .4s; }
    .history .slick-slide.slick-active {
      opacity: 1; }
  .history .slick-dots {
    position: absolute;
    left: 0;
    bottom: 47px;
    display: flex !important;
    justify-content: center;
    width: 50%;
    font-size: 0; }
    @media only screen and (max-width: 767px) {
      .history .slick-dots {
        top: 176px;
        bottom: auto;
        width: 100%; } }
    .history .slick-dots li button {
      position: relative;
      width: 8px;
      height: 8px;
      background: none;
      font-size: 0; }
      .history .slick-dots li button:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #FCFCFD;
        border-radius: 50%;
        opacity: .7;
        transform: scale(0.5);
        transition: all .2s; }
      .history .slick-dots li button:hover:before {
        opacity: 1; }
    .history .slick-dots li.slick-active button:before {
      opacity: 1;
      transform: scale(1); }
    .history .slick-dots li:not(:last-child) {
      margin-right: 8px; }

.story .stage-small {
  margin-bottom: 96px; }
  @media only screen and (max-width: 1023px) {
    .story .stage-small {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .story .stage-small {
      margin-bottom: 32px; } }

.story__row {
  display: flex; }
  @media only screen and (max-width: 1179px) {
    .story__row {
      margin-left: -150px; } }
  @media only screen and (max-width: 1023px) {
    .story__row {
      display: block;
      margin: 0; } }

.story__col:first-child {
  flex: 0 0 448px; }
  @media only screen and (max-width: 1023px) {
    .story__col:first-child {
      margin-bottom: 48px; } }

.story__col:nth-child(2) {
  flex-grow: 1;
  padding-left: 128px; }
  @media only screen and (max-width: 1339px) {
    .story__col:nth-child(2) {
      padding-left: 80px; } }
  @media only screen and (max-width: 1023px) {
    .story__col:nth-child(2) {
      padding: 0; } }

.story__preview img {
  width: 100%;
  border-radius: 16px; }

.story__title {
  margin-bottom: 32px; }

.story__info {
  margin-bottom: 64px;
  color: #777E90; }

.story__list {
  display: flex;
  margin: 0 -16px 64px; }
  @media only screen and (max-width: 767px) {
    .story__list {
      display: block;
      margin: 0 0 48px; } }

.story__item {
  flex: 0 0 calc(50% - 32px);
  width: calc(50% - 32px);
  margin: 0 16px; }
  @media only screen and (max-width: 767px) {
    .story__item {
      width: 100%;
      margin: 0; }
      .story__item:not(:last-child) {
        margin-bottom: 48px; } }

.story__category {
  position: relative;
  margin-bottom: 32px;
  padding-bottom: 34px;
  font-weight: 600; }
  @media only screen and (max-width: 767px) {
    .story__category {
      margin-bottom: 24px;
      padding-bottom: 26px; } }
  .story__category:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 160px;
    height: 2px;
    background: #E6E8EC; }
    body.dark .story__category:before {
      background: #353945; }

.story__content {
  color: #777E90; }

.post {
  overflow: hidden; }
  .post__title {
    margin-bottom: 20px;
    text-align: center; }
  .post__info {
    max-width: 455px;
    margin: 0 auto 80px;
    text-align: center;
    color: #353945; }
    @media only screen and (max-width: 1179px) {
      .post__info {
        margin-bottom: 64px; } }
    @media only screen and (max-width: 767px) {
      .post__info {
        margin-bottom: 32px; } }
    body.dark .post__info {
      color: #E6E8EC; }
  .post__nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: -12px -6px 80px; }
    @media only screen and (max-width: 1179px) {
      .post__nav {
        margin-bottom: 64px; } }
    @media only screen and (max-width: 767px) {
      .post__nav {
        margin-bottom: 32px; } }
  .post__link {
    margin: 12px 6px 0;
    padding: 6px 12px;
    border-radius: 14px;
    background: none;
    font-family: 'SFProDisplay-Bold';
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90;
    transition: all .2s; }
    .post__link:hover {
      color: #353945; }
      body.dark .post__link:hover {
        color: #E6E8EC; }
    .post__link.active {
      background: #353945;
      color: #FCFCFD; }
      body.dark .post__link.active {
        background: #FCFCFD;
        color: #23262F; }
  .post__box {
    display: none; }
    .post__box:first-child {
      display: block; }
  .post__list {
    display: flex;
    flex-wrap: wrap;
    margin: -64px -16px 0; }
    @media only screen and (max-width: 767px) {
      .post__list {
        display: block;
        margin: 0; } }
  .post__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 0 0 calc(33.333% - 32px);
    width: calc(33.333% - 32px);
    margin: 64px 16px 0;
    padding-bottom: 40px;
    border-bottom: 2px solid #E6E8EC;
    color: #23262F; }
    @media only screen and (max-width: 1179px) {
      .post__item {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px); } }
    @media only screen and (max-width: 767px) {
      .post__item {
        width: 100%;
        margin: 0; }
        .post__item:not(:last-child) {
          margin-bottom: 64px; } }
    body.dark .post__item {
      border-color: #353945;
      color: #FCFCFD; }
    .post__item:hover .post__preview {
      box-shadow: 0 0 0 3px #FF592C; }
      .post__item:hover .post__preview img {
        transform: scale(1.1); }
        @media only screen and (max-width: 767px) {
          .post__item:hover .post__preview img {
            transform: scale(1); } }
  .post__preview {
    width: 100%;
    overflow: hidden;
    margin-bottom: 32px;
    border-radius: 16px;
    transition: box-shadow .2s; }
    .post__preview img {
      width: 100%;
      transition: transform 2s; }
  .post__status {
    margin-bottom: 16px; }
  .post__subtitle {
    flex-grow: 1;
    margin-bottom: 34px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600; }
  .post__foot {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 500;
    color: #777E90; }
  .post__user {
    display: flex;
    align-items: center;
    margin-right: auto; }
  .post__avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 50%;
    background: #E6E8EC;
    overflow: hidden; }
    body.dark .post__avatar {
      background: #353945; }
    .post__avatar img {
      width: 100%;
      min-height: 100%; }
  .post__btns {
    margin-top: 80px;
    text-align: center; }
    @media only screen and (max-width: 1179px) {
      .post__btns {
        margin-top: 64px; } }
    @media only screen and (max-width: 767px) {
      .post__btns {
        margin-top: 32px; } }

.article__head {
  display: flex;
  margin-bottom: 112px; }
  @media only screen and (max-width: 1179px) {
    .article__head {
      margin-bottom: 80px; } }
  @media only screen and (max-width: 767px) {
    .article__head {
      flex-direction: column-reverse;
      margin-bottom: 64px; } }
  .article__head .article__title {
    max-width: 920px;
    margin-right: auto; }
  .article__head .article__button {
    margin-left: 30px; }
    @media only screen and (max-width: 767px) {
      .article__head .article__button {
        margin: 0 0 16px; } }

.article__row {
  display: flex;
  align-items: center; }
  @media only screen and (max-width: 1023px) {
    .article__row {
      flex-direction: column-reverse; } }

.article__col:first-child {
  flex: 0 0 256px;
  margin-right: auto; }
  @media only screen and (max-width: 1179px) {
    .article__col:first-child {
      margin-right: 80px; } }
  @media only screen and (max-width: 1023px) {
    .article__col:first-child {
      max-width: 400px;
      margin: 0 auto; } }

.article__col:nth-child(2) {
  flex: 0 0 640px; }
  @media only screen and (max-width: 1179px) {
    .article__col:nth-child(2) {
      flex: 1 1 auto; } }
  @media only screen and (max-width: 1023px) {
    .article__col:nth-child(2) {
      flex: 0 0 auto;
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .article__col:nth-child(2) {
      margin-bottom: 48px; } }
  .article__col:nth-child(2) .article__title {
    margin-bottom: 32px; }
  .article__col:nth-child(2) .article__preview {
    margin-bottom: 80px; }
    @media only screen and (max-width: 767px) {
      .article__col:nth-child(2) .article__preview {
        margin-bottom: 48px; } }

.article__preview img {
  width: 100%;
  border-radius: 16px; }

.article__info {
  margin-bottom: 84px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -.01em;
  color: #777E90; }
  @media only screen and (max-width: 767px) {
    .article__info {
      margin-bottom: 48px;
      font-size: 16px;
      line-height: 1.5; } }

.article__content {
  color: #777E90; }
  .article__content p:not(:last-child) {
    margin-bottom: 24px; }

.breadcrumbs {
  margin-bottom: 72px; }
  @media only screen and (max-width: 1179px) {
    .breadcrumbs {
      margin-bottom: 64; } }
  @media only screen and (max-width: 767px) {
    .breadcrumbs {
      margin-bottom: 32px; } }
  .breadcrumbs__wrap {
    padding: 112px 64px;
    border-radius: 24px;
    background: #E6E8EC;
    text-align: center; }
    @media only screen and (max-width: 1023px) {
      .breadcrumbs__wrap {
        padding: 80px 48px; } }
    @media only screen and (max-width: 767px) {
      .breadcrumbs__wrap {
        padding: 32px 16px; } }
    body.dark .breadcrumbs__wrap {
      background: #353945; }
  .breadcrumbs__title {
    margin-bottom: 12px; }
  .breadcrumbs__info {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #777E90; }
    @media only screen and (max-width: 767px) {
      .breadcrumbs__info {
        font-size: 16px; } }
  .breadcrumbs__list {
    margin-left: -34px; }
    @media only screen and (max-width: 767px) {
      .breadcrumbs__list {
        margin-left: -20px; } }
  .breadcrumbs__item {
    display: inline;
    margin-left: 34px;
    font-family: 'SFProDisplay-Bold';
    font-size: 14px;
    line-height: 1.14286;
    font-weight: 700;
    color: #777E90; }
    @media only screen and (max-width: 767px) {
      .breadcrumbs__item {
        margin-left: 20px; } }
    .breadcrumbs__item:not(:last-child):after {
      content: "";
      display: inline-block;
      width: 6px;
      height: 10px;
      margin-left: 34px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='10' fill='none' viewBox='0 0 6 10'%3E%3Cpath fill-rule='evenodd' d='M.293.793a1 1 0 0 0 0 1.414L3.086 5 .293 7.793a1 1 0 1 0 1.414 1.414l3.5-3.5a1 1 0 0 0 0-1.414l-3.5-3.5a1 1 0 0 0-1.414 0z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto; }
      @media only screen and (max-width: 767px) {
        .breadcrumbs__item:not(:last-child):after {
          margin-left: 20px; } }
  .breadcrumbs__link {
    color: #777E90;
    transition: color .2s; }
    .breadcrumbs__link:hover {
      color: #3772FF; }

.card {
  display: flex;
  flex-direction: column;
  color: #23262F; }
  body.dark .card {
    color: #FCFCFD; }
  .card:hover .card__preview {
    box-shadow: 0 0 0 3px #FF592C; }
    .card:hover .card__preview img {
      transform: scale(1.1); }
      @media only screen and (max-width: 767px) {
        .card:hover .card__preview img {
          transform: scale(1); } }
  .card__preview {
    position: relative;
    overflow: hidden;
    border-radius: 16px;
    transition: box-shadow .2s; }
    .card__preview img {
      width: 100%;
      border-radius: 16px;
      transition: transform 2s; }
  .card__category {
    position: absolute;
    top: 8px;
    left: 8px; }
  .card__head {
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    padding: 20px 0 12px; }
    @media only screen and (max-width: 767px) {
      .card__head {
        display: block;
        padding: 16px 0; } }
  .card__level {
    flex-shrink: 0;
    margin-left: 10px; }
    @media only screen and (max-width: 767px) {
      .card__level {
        margin: 8px 0 0 56px; } }
  .card__user {
    display: flex;
    margin-right: auto; }
    @media only screen and (max-width: 767px) {
      .card__user {
        margin-right: 0; } }
  .card__avatar {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin: 5px 16px 0 0;
    border-radius: 50%;
    background: #E6E8EC; }
    body.dark .card__avatar {
      background: #353945; }
    .card__avatar img {
      width: 100%;
      height: 100%;
      border-radius: 50%; }
  .card__title {
    margin-bottom: 2px;
    font-weight: 600; }
  .card__trainer {
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
  .card__parameters {
    display: flex;
    justify-content: space-between;
    padding: 12px 0 20px;
    border-top: 1px solid #E6E8EC; }
    body.dark .card__parameters {
      border-color: #353945; }
  .card__parameter {
    display: flex;
    align-items: center;
    font-size: 12px;
    line-height: 1.66667;
    font-weight: 600;
    color: #353945; }
    body.dark .card__parameter {
      color: #E6E8EC; }
    .card__parameter .icon {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      fill: #777E90; }

.catalog__head {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px; }
  @media only screen and (max-width: 767px) {
    .catalog__head {
      display: block;
      margin-bottom: 20px; } }

.catalog__title {
  padding-top: 8px;
  font-size: 24px;
  line-height: 1.33333;
  letter-spacing: -.01em; }
  @media only screen and (max-width: 767px) {
    .catalog__title {
      display: none; } }

.catalog__search {
  position: relative;
  flex-shrink: 0;
  width: 320px;
  margin-left: 30px; }
  @media only screen and (max-width: 1023px) {
    .catalog__search {
      width: 260px; } }
  @media only screen and (max-width: 767px) {
    .catalog__search {
      width: 100%;
      margin: 0; } }

.catalog__input {
  width: 100%;
  height: 48px;
  padding: 0 48px 0 14px;
  border-radius: 24px;
  background: none;
  border: 2px solid #E6E8EC;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
  line-height: 1.71429;
  color: #23262F;
  transition: border-color .2s; }
  body.dark .catalog__input {
    border-color: #353945;
    color: #FCFCFD; }
  .catalog__input::placeholder {
    color: #777E90; }
  .catalog__input:focus {
    border-color: #777E90; }

.catalog__result {
  position: absolute;
  top: 8px;
  right: 8px;
  bottom: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #FF592C;
  transition: background .2s; }
  .catalog__result .icon {
    width: 16px;
    height: 16px;
    fill: #FCFCFD; }
  .catalog__result:hover {
    background: #f83500; }

.catalog__wrap {
  margin-bottom: 80px;
  padding-top: 32px;
  border-top: 1px solid #E6E8EC; }
  @media only screen and (max-width: 1023px) {
    .catalog__wrap {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .catalog__wrap {
      margin-bottom: 32px;
      padding-top: 0;
      border: none; } }
  body.dark .catalog__wrap {
    border-color: #353945; }

.catalog__top {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  padding-bottom: 32px;
  border-bottom: 1px solid #E6E8EC; }
  @media only screen and (max-width: 767px) {
    .catalog__top {
      display: block;
      margin-bottom: 20px;
      padding-bottom: 0;
      border: none; } }
  body.dark .catalog__top {
    border-color: #353945; }
  .catalog__top .select {
    width: 180px; }
    @media only screen and (max-width: 1023px) {
      .catalog__top .select {
        width: 260px; } }
    @media only screen and (max-width: 767px) {
      .catalog__top .select {
        width: 100%;
        margin-bottom: 20px; } }

.catalog__nav {
  position: absolute;
  top: 10px;
  left: 50%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%); }
  @media only screen and (max-width: 1023px) {
    .catalog__nav {
      display: none; } }

.catalog__link {
  margin: 0 6px;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  font-family: 'SFProDisplay-Bold';
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777E90;
  transition: all .2s; }
  .catalog__link:hover {
    color: #353945; }
    body.dark .catalog__link:hover {
      color: #E6E8EC; }
  .catalog__link.active {
    background: #353945;
    color: #FCFCFD; }
    body.dark .catalog__link.active {
      background: #FCFCFD;
      color: #23262F; }

.catalog__filter {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 0 0 24px;
  flex-shrink: 0;
  border-radius: 24px;
  background: #FF592C;
  font-family: 'SFProDisplay-Bold';
  font-size: 16px;
  line-height: 48px;
  color: #FCFCFD; }

.catalog__close {
  width: 52px;
  padding-right: 10px;
  background: none;
  font-size: 0; }
  .catalog__close .icon {
    width: 10px;
    height: 10px;
    fill: #FCFCFD;
    transition: transform .2s; }
  .catalog__close:hover .icon {
    transform: rotate(90deg); }

.catalog__sorting {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0; }
  @media only screen and (max-width: 1179px) {
    .catalog__sorting {
      margin: -24px -8px 0; } }
  @media only screen and (max-width: 1023px) {
    .catalog__sorting {
      margin: -32px -16px 0; } }
  @media only screen and (max-width: 767px) {
    .catalog__sorting {
      display: block;
      margin: 0; } }

.catalog__cell {
  flex: 0 0 calc(25% - 32px);
  max-width: calc(25% - 32px);
  margin: 32px 16px 0; }
  @media only screen and (max-width: 1179px) {
    .catalog__cell {
      flex: 0 0 calc(25% - 16px);
      max-width: calc(25% - 16px);
      margin: 24px 8px 0; } }
  @media only screen and (max-width: 1023px) {
    .catalog__cell {
      flex: 0 0 calc(50% - 32px);
      max-width: calc(50% - 32px);
      margin: 32px 16px 0; } }
  @media only screen and (max-width: 767px) {
    .catalog__cell {
      max-width: 100%;
      margin: 0; }
      .catalog__cell:not(:last-child) {
        margin-bottom: 20px; } }

.catalog__label {
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1;
  font-weight: 700;
  text-transform: uppercase;
  color: #B1B5C3; }

.catalog__list {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0; }
  @media only screen and (max-width: 767px) {
    .catalog__list {
      display: block;
      margin: 0; } }

.catalog .card {
  flex: 0 0 calc(33.333% - 32px);
  max-width: calc(33.333% - 32px);
  margin: 32px 16px 0; }
  @media only screen and (max-width: 1260px) {
    .catalog .card {
      flex: 0 0 calc(50% - 32px);
      max-width: calc(50% - 32px); } }
  @media only screen and (max-width: 767px) {
    .catalog .card {
      max-width: 100%;
      margin: 0; }
      .catalog .card:not(:last-child) {
        margin-bottom: 32px; } }

.catalog__btns {
  margin-top: 80px;
  text-align: center; }
  @media only screen and (max-width: 1179px) {
    .catalog__btns {
      margin-top: 64px; } }
  @media only screen and (max-width: 767px) {
    .catalog__btns {
      margin-top: 32px; } }

.description {
  padding-top: 80px;
  overflow: hidden; }
  .description__center {
    display: flex;
    align-items: flex-start; }
    @media only screen and (max-width: 1023px) {
      .description__center {
        display: block; } }
  .description__col:first-child {
    flex: 0 0 calc(100% - 352px);
    max-width: calc(100% - 352px);
    padding-right: 96px; }
    @media only screen and (max-width: 1179px) {
      .description__col:first-child {
        flex: 0 0 calc(100% - 305px);
        max-width: calc(100% - 305px);
        padding-right: 32px; } }
    @media only screen and (max-width: 1023px) {
      .description__col:first-child {
        max-width: 100%;
        margin-bottom: 48px;
        padding: 0; } }
    @media only screen and (max-width: 767px) {
      .description__col:first-child {
        margin-bottom: 32px; } }
  .description__col:nth-child(2) {
    flex: 0 0 352px;
    max-width: 352px; }
    @media only screen and (max-width: 1179px) {
      .description__col:nth-child(2) {
        flex: 0 0 305px;
        max-width: 305px; } }
    @media only screen and (max-width: 1023px) {
      .description__col:nth-child(2) {
        max-width: 100%; } }
  .description__details {
    display: flex;
    margin-bottom: 16px; }
  .description__status:not(:last-child) {
    margin-right: 4px; }
  .description__title {
    margin-bottom: 8px; }
  .description__info {
    margin-bottom: 48px;
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #777E90; }
    @media only screen and (max-width: 767px) {
      .description__info {
        margin-bottom: 24px;
        font-size: 16px; } }
  .description__wrap {
    margin: 0 -10px; }
  .description__slider {
    visibility: hidden; }
    .description__slider.slick-initialized {
      visibility: visible; }
  .description__preview {
    position: relative; }
    .description__preview img {
      width: 100%;
      border-radius: 16px; }
  .description__item {
    display: flex;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 500; }
    .description__item:not(:last-child) {
      margin-bottom: 20px; }
  .description__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 20px;
    height: 24px;
    margin-right: 12px; }
    .description__icon .icon {
      width: 16px;
      height: 16px;
      fill: #777E90; }
      .description__icon .icon-scoreboard {
        width: 20px;
        height: 20px; }
      .description__icon .icon-candlesticks {
        width: 18px;
        height: 18px; }
  .description__parameter {
    flex: 0 0 156px;
    padding-right: 16px;
    color: #777E90; }
  .description__text {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid #E6E8EC;
    color: #353945; }
    @media only screen and (max-width: 1179px) {
      .description__text {
        margin-top: 32px;
        padding-top: 32px; } }
    body.dark .description__text {
      border-color: #353945;
      color: #E6E8EC; }
    .description__text p:not(:last-child) {
      margin-bottom: 24px; }
  .description__list {
    margin-top: 40px;
    padding: 24px 20px;
    border-radius: 8px;
    border: 2px solid #E6E8EC; }
    @media only screen and (max-width: 1179px) {
      .description__list {
        margin-top: 32px; } }
    body.dark .description__list {
      border-color: #353945; }
    .description__list li {
      position: relative;
      padding-left: 26px;
      font-weight: 600; }
      .description__list li:before {
        content: "";
        position: absolute;
        top: 2px;
        left: 0;
        width: 16px;
        height: 16px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'%3E%3Cpath fill-rule='evenodd' d='M8.999 17.333c4.602 0 8.333-3.731 8.333-8.333S13.602.667 8.999.667.666 4.398.666 9s3.731 8.333 8.333 8.333zm4.874-10.126a1 1 0 0 0-1.414-1.414l-4.293 4.293-1.793-1.793a1 1 0 1 0-1.414 1.414l2.5 2.5a1 1 0 0 0 1.414 0l5-5z' fill='%2345b36b'/%3E%3C/svg%3E") no-repeat 50% 50%/100% auto; }
      .description__list li:not(:last-child) {
        margin-bottom: 8px; }
  .description__btns {
    display: flex;
    margin-top: 48px; }
    @media only screen and (max-width: 1179px) {
      .description__btns {
        margin-top: 40px; } }
    .description__btns .description__button:first-child {
      margin-right: 12px; }
    .description__btns .description__button:nth-child(2) {
      flex-grow: 1; }
  .description .slick-list {
    font-size: 0; }
  .description .slick-slide {
    padding: 0 10px; }
  .description .slick-dots {
    display: flex !important;
    justify-content: center;
    margin-top: 32px;
    font-size: 0; }
    .description .slick-dots li button {
      position: relative;
      width: 8px;
      height: 8px;
      background: none;
      border: 1px solid #777E90;
      border-radius: 50%;
      font-size: 0;
      transition: all .2s; }
      .description .slick-dots li button:hover {
        border-color: #FF592C; }
    .description .slick-dots li.slick-active button {
      background: #FF592C;
      border-color: #FF592C; }
    .description .slick-dots li:not(:last-child) {
      margin-right: 12px; }

.interesting {
  overflow: hidden; }
  .interesting__wrapper {
    position: relative; }
  .interesting__slider {
    position: static;
    visibility: hidden; }
    @media only screen and (max-width: 767px) {
      .interesting__slider {
        position: relative;
        padding-top: 72px; } }
    .interesting__slider.slick-initialized {
      visibility: visible; }
  .interesting__title {
    margin-bottom: 64px; }
    @media only screen and (max-width: 767px) {
      .interesting__title {
        margin-bottom: 24px;
        text-align: center; } }
  .interesting__wrap {
    margin: 0 -16px; }
  .interesting .card {
    height: 100%; }
  .interesting__btns {
    margin-top: 64px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .interesting__btns {
        margin-top: 48px; } }
  .interesting .slick-list {
    overflow: visible; }
  .interesting .slick-track {
    display: flex; }
  .interesting .slick-slide {
    height: auto;
    padding: 0 16px;
    opacity: 0;
    transition: opacity .4s; }
    .interesting .slick-slide.slick-active {
      opacity: 1; }
    .interesting .slick-slide > div {
      display: flex;
      height: 100%;
      flex-grow: 1; }
  .interesting .slick-arrow {
    top: 16px; }
    @media only screen and (max-width: 767px) {
      .interesting .slick-arrow {
        top: 0; } }
  .interesting .slick-prev {
    right: 44px; }
    @media only screen and (max-width: 767px) {
      .interesting .slick-prev {
        right: auto;
        left: calc(50% - 34px); } }
  .interesting .slick-next {
    right: 0; }
    @media only screen and (max-width: 767px) {
      .interesting .slick-next {
        right: calc(50% - 34px); } }

.programs {
  position: relative;
  overflow: hidden; }
  .programs__anchor {
    position: absolute;
    top: -64px;
    left: 0;
    right: 0; }
    @media only screen and (max-width: 1179px) {
      .programs__anchor {
        top: -40px; } }
  .programs__top {
    max-width: 620px;
    margin: 0 auto 80px;
    text-align: center; }
    @media only screen and (max-width: 1179px) {
      .programs__top {
        margin-bottom: 64px; } }
    @media only screen and (max-width: 767px) {
      .programs__top {
        margin-bottom: 40px; } }
  .programs__title {
    margin-bottom: 16px; }
  .programs__info {
    margin-bottom: 40px;
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
    .programs__info strong {
      font-weight: 600; }
  .programs__green {
    color: #45B26B; }
  .programs__black {
    color: #23262F; }
    body.dark .programs__black {
      color: #FCFCFD; }
  .programs__wrap {
    margin: 0 -12px; }
  .programs__slider {
    padding-bottom: 104px;
    visibility: hidden; }
    @media only screen and (max-width: 1179px) {
      .programs__slider {
        padding-bottom: 88px; } }
    @media only screen and (max-width: 767px) {
      .programs__slider {
        padding-bottom: 64px; } }
    .programs__slider.slick-initialized {
      visibility: visible; }
  .programs__item {
    height: 100%;
    padding: 64px 32px;
    background: #F4F5F6;
    border-radius: 20px;
    transition: all .2s; }
    @media only screen and (max-width: 1179px) {
      .programs__item {
        padding: 48px 24px; } }
    @media only screen and (max-width: 767px) {
      .programs__item {
        padding: 64px 24px; } }
    .programs__item:hover {
      box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
      background: #FCFCFD; }
      .programs__item:hover .programs__button {
        background: #FF592C;
        box-shadow: 0 0 0 2px #FF592C inset;
        color: #FCFCFD; }
        .programs__item:hover .programs__button:hover {
          background: #f83500;
          box-shadow: 0 0 0 2px #f83500 inset;
          color: #FCFCFD; }
    body.dark .programs__item {
      background: #23262F; }
      body.dark .programs__item:hover {
        background: transparent;
        box-shadow: inset 0 0 0 1px #23262F; }
  .programs__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    margin-bottom: 32px;
    border-radius: 50%; }
  .programs__subtitle {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600; }
  .programs__content {
    margin-bottom: 32px;
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
  .programs .slick-track {
    display: flex; }
  .programs .slick-slide {
    height: auto;
    padding: 0 12px; }
  .programs .slick-arrow {
    bottom: 0; }
  .programs .slick-prev {
    left: calc(50% - 34px); }
  .programs .slick-next {
    right: calc(50% - 34px); }

.trainers__title {
  margin-bottom: 20px;
  text-align: center; }

.trainers__info {
  max-width: 445px;
  margin: 0 auto 40px;
  text-align: center;
  color: #353945; }
  body.dark .trainers__info {
    color: #E6E8EC; }

.trainers__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -12px -6px 80px; }
  @media only screen and (max-width: 1179px) {
    .trainers__nav {
      margin-bottom: 64px; } }
  @media only screen and (max-width: 767px) {
    .trainers__nav {
      display: none; } }

.trainers__link {
  margin: 12px 6px 0;
  padding: 6px 12px;
  border-radius: 14px;
  background: none;
  font-family: 'SFProDisplay-Bold';
  font-size: 14px;
  line-height: 1.14286;
  font-weight: 700;
  color: #777E90;
  transition: all .2s; }
  .trainers__link:hover {
    color: #353945; }
    body.dark .trainers__link:hover {
      color: #E6E8EC; }
  .trainers__link.active {
    background: #353945;
    color: #FCFCFD; }
    body.dark .trainers__link.active {
      background: #23262F;
      color: #FCFCFD; }

.trainers .select {
  display: none; }
  @media only screen and (max-width: 767px) {
    .trainers .select {
      display: block;
      margin-bottom: 48px; } }

.trainers__box {
  display: none; }
  .trainers__box:first-child {
    display: block; }

.trainers__list {
  display: flex;
  flex-wrap: wrap;
  margin: -32px -16px 0; }
  @media only screen and (max-width: 767px) {
    .trainers__list {
      margin: -24px -8px 0; } }

.trainers__item {
  flex: 0 0 calc(25% - 32px);
  width: calc(25% - 32px);
  margin: 32px 16px 0; }
  @media only screen and (max-width: 1023px) {
    .trainers__item {
      flex: 0 0 calc(33.333% - 32px);
      width: calc(33.333% - 32px); } }
  @media only screen and (max-width: 767px) {
    .trainers__item {
      flex: 0 0 calc(50% - 16px);
      width: calc(50% - 16px);
      margin: 24px 8px 0; } }

.trainers__element {
  position: relative;
  display: block;
  padding: 48px 32px;
  box-shadow: inset 0 0 0 1px #E6E8EC;
  border-radius: 24px;
  text-align: center;
  color: #23262F;
  transition: box-shadow .2s;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
  @media only screen and (max-width: 1179px) {
    .trainers__element {
      padding: 48px 16px; } }
  @media only screen and (max-width: 767px) {
    .trainers__element {
      padding: 24px 12px; } }
  body.dark .trainers__element {
    box-shadow: inset 0 0 0 1px #353945;
    background: #23262F;
    color: #FCFCFD; }
  .trainers__element:hover {
    box-shadow: inset 0 0 0 2px #B1B5C3; }
    body.dark .trainers__element:hover {
      box-shadow: inset 0 0 0 2px #777E90; }

.trainers__avatar {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;
  margin: 0 auto 32px;
  border-radius: 50%;
  overflow: hidden;
  background: #E6E8EC; }
  @media only screen and (max-width: 767px) {
    .trainers__avatar {
      width: 80px;
      height: 80px;
      margin-bottom: 16px; } }
  body.dark .trainers__avatar {
    background: #353945; }
  .trainers__avatar img {
    width: 100%;
    min-height: 100%; }

.trainers__name {
  margin-bottom: 4px;
  font-weight: 500; }
  @media only screen and (max-width: 767px) {
    .trainers__name {
      font-size: 12px; } }

.trainers__position {
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }
  @media only screen and (max-width: 767px) {
    .trainers__position {
      font-size: 12px; } }

.portfolio {
  padding: 40px 0 124px; }
  @media only screen and (max-width: 767px) {
    .portfolio {
      padding-bottom: 64px; } }
  .portfolio__top {
    max-width: 670px;
    margin: 0 auto 102px;
    text-align: center; }
    @media only screen and (max-width: 767px) {
      .portfolio__top {
        margin-bottom: 64px; } }
    .portfolio__top .portfolio__status {
      margin-bottom: 16px; }
  .portfolio__title {
    margin-bottom: 8px; }
  .portfolio__info {
    font-size: 24px;
    line-height: 1.33333;
    letter-spacing: -.01em;
    color: #777E90; }
  .portfolio__gallery {
    position: relative;
    left: -5%;
    max-width: 512px;
    margin: 0 auto 108px;
    pointer-events: none; }
    @media only screen and (max-width: 1179px) {
      .portfolio__gallery {
        max-width: 400px; } }
    @media only screen and (max-width: 767px) {
      .portfolio__gallery {
        margin-bottom: 64px; } }
    @media only screen and (max-width: 474px) {
      .portfolio__gallery {
        left: -11%;
        max-width: 280px; } }
  .portfolio__preview:first-child {
    position: relative;
    z-index: 2; }
  .portfolio__preview:nth-child(2) {
    top: 35.5%;
    right: -19%;
    z-index: 3;
    width: 45%; }
  .portfolio__preview:nth-child(3) {
    top: -12%;
    right: -6%;
    z-index: 1;
    width: 43%; }
  .portfolio__preview:nth-child(4) {
    left: 11%;
    bottom: 14%;
    z-index: 3;
    width: 18%; }
  .portfolio__preview:not(:first-child) {
    position: absolute; }
  .portfolio__preview img {
    width: 100%; }
  .portfolio__row {
    display: flex;
    align-items: flex-start; }
    @media only screen and (max-width: 1023px) {
      .portfolio__row {
        display: block; } }
  .portfolio__trainer {
    flex: 0 0 256px;
    padding: 24px;
    border-radius: 24px;
    border: 1px solid #E6E8EC;
    text-align: center; }
    @media only screen and (max-width: 1179px) {
      .portfolio__trainer {
        flex: 0 0 220px; } }
    @media only screen and (max-width: 1023px) {
      .portfolio__trainer {
        margin-bottom: 32px; } }
    body.dark .portfolio__trainer {
      background: #23262F;
      border-color: #353945; }
    .portfolio__trainer .portfolio__status {
      margin-bottom: 32px; }
  .portfolio__avatar {
    width: 128px;
    height: 128px;
    margin: 0 auto 32px;
    background: #E6E8EC;
    border-radius: 50%;
    overflow: hidden; }
    body.dark .portfolio__avatar {
      background: #353945; }
    .portfolio__avatar img {
      width: 100%;
      min-height: 100%; }
  .portfolio__man {
    margin-bottom: 4px;
    font-weight: 600; }
  .portfolio__position {
    font-size: 14px;
    line-height: 1.71429;
    color: #777E90; }
  .portfolio__wrap {
    flex-grow: 1;
    padding: 7px 64px 0 32px; }
    @media only screen and (max-width: 1339px) {
      .portfolio__wrap {
        padding: 0 32px; } }
    @media only screen and (max-width: 1023px) {
      .portfolio__wrap {
        margin-bottom: 32px;
        padding: 0; } }
  .portfolio__item {
    display: flex;
    font-size: 14px;
    line-height: 1.71429;
    font-weight: 500; }
    .portfolio__item:not(:last-child) {
      margin-bottom: 20px; }
  .portfolio__icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 20px;
    height: 24px;
    margin-right: 12px; }
    .portfolio__icon .icon {
      width: 16px;
      height: 16px;
      fill: #777E90; }
      .portfolio__icon .icon-scoreboard {
        width: 20px;
        height: 20px; }
      .portfolio__icon .icon-candlesticks {
        width: 18px;
        height: 18px; }
  .portfolio__parameter {
    flex: 0 0 156px;
    padding-right: 16px;
    color: #777E90; }
  .portfolio__note {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid #E6E8EC;
    color: #353945; }
    body.dark .portfolio__note {
      border-color: #353945;
      color: #E6E8EC; }
  .portfolio__details {
    flex: 0 0 416px;
    padding-left: 64px;
    border-left: 1px solid #E6E8EC; }
    @media only screen and (max-width: 1339px) {
      .portfolio__details {
        flex: 0 0 350px;
        padding: 0;
        border: none; } }
    @media only screen and (max-width: 1179px) {
      .portfolio__details {
        flex: 0 0 272px; } }
    body.dark .portfolio__details {
      border-color: #353945; }
  .portfolio__head {
    display: flex;
    align-items: center;
    margin-bottom: 42px;
    font-size: 24px;
    line-height: 1.33333;
    font-weight: 600; }
    .portfolio__head .icon {
      position: relative;
      bottom: -1px;
      width: 22px;
      height: 22px;
      margin-right: 14px; }
      body.dark .portfolio__head .icon {
        fill: #FCFCFD; }
  .portfolio__control {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    margin-bottom: 20px;
    padding: 0 8px;
    border-radius: 24px;
    background: #23262F; }
    body.dark .portfolio__control {
      background: #FCFCFD; }
  .portfolio__date {
    padding: 0 10px;
    text-align: center;
    font-family: 'SFProDisplay-Bold';
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    color: #FCFCFD; }
    body.dark .portfolio__date {
      color: #23262F; }
  .portfolio__prev, .portfolio__next {
    flex-shrink: 0;
    width: 32px;
    height: 32px;
    background: none; }
    .portfolio__prev .icon, .portfolio__next .icon {
      width: 14px;
      height: 14px;
      fill: #FCFCFD;
      transition: transform .2s; }
      body.dark .portfolio__prev .icon, body.dark .portfolio__next .icon {
        fill: #23262F; }
  .portfolio__prev:hover .icon {
    transform: translateX(-2px); }
  .portfolio__next:hover .icon {
    transform: translateX(2px); }
  .portfolio__variants {
    display: flex;
    flex-wrap: wrap;
    margin: -8px 0 20px -8px; }
  .portfolio__variant {
    margin: 8px 0 0 8px;
    padding: 0 25px;
    background: none;
    line-height: 48px;
    border-radius: 24px;
    box-shadow: inset 0 0 0 2px #E6E8EC;
    font-family: 'SFProDisplay-Bold';
    font-size: 16px;
    transition: background .2s; }
    .portfolio__variant:hover {
      background: #E6E8EC; }
    body.dark .portfolio__variant {
      box-shadow: inset 0 0 0 2px #777E90;
      color: #FCFCFD; }
      body.dark .portfolio__variant:hover {
        background: #777E90; }
  .portfolio__btns {
    display: flex; }
    .portfolio__btns .portfolio__button:first-child {
      margin-right: 12px; }
    .portfolio__btns .portfolio__button:nth-child(2) {
      flex-grow: 1; }

.popup {
  position: relative; }
  .popup .mfp-close {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    line-height: normal;
    opacity: 1;
    box-shadow: 0px 8px 16px -8px rgba(15, 15, 15, 0.2);
    background: #FCFCFD url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' fill='none' viewBox='0 0 14 15'%3E%3Cpath fill-rule='evenodd' d='M.293 1.289a1 1 0 0 1 1.414 0L7 6.582l5.293-5.293a1 1 0 0 1 1.414 1.414L8.414 7.996l5.293 5.293a1 1 0 0 1-1.414 1.414L7 9.41l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414l5.293-5.293L.293 2.703a1 1 0 0 1 0-1.414z' fill='%23777e91'/%3E%3C/svg%3E") no-repeat 50% 50%/14px auto;
    font-size: 0;
    transition: transform .2s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }
    body.dark .popup .mfp-close {
      box-shadow: inset 0 0 0 1px #353945;
      background-color: #141416;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'%3E%3Cpath fill='%23FCFCFD' d='M1.613.2l.094.083L5 3.585 8.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 .083 1.32l-.083.094L6.415 5l3.292 3.293a1 1 0 0 1-1.32 1.497l-.094-.083L5 6.415 1.707 9.707A1 1 0 0 1 .21 8.387l.083-.094L3.585 5 .293 1.707a1 1 0 0 1 0-1.414A1 1 0 0 1 1.613.21z'/%3E%3C/svg%3E"); }
    .popup .mfp-close:hover {
      transform: scale(1.05); }
  .popup_trainer {
    max-width: 580px;
    margin: 15px auto;
    padding: 64px 64px 50px;
    background: #FCFCFD;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
    border-radius: 16px; }
    @media only screen and (max-width: 767px) {
      .popup_trainer {
        padding: 48px 16px 32px; } }
    body.dark .popup_trainer {
      background: #141416;
      box-shadow: inset 0 0 0 1px #353945; }
    .popup_trainer .mfp-close {
      top: -16px;
      right: -16px; }

.mfp-container {
  padding: 20px; }
  @media only screen and (max-width: 767px) {
    .mfp-container {
      padding: 0; } }

@media (max-width: 900px) {
  .mfp-container {
    padding: 30px 20px; } }

.mfp-bg {
  background: rgba(20, 20, 22, 0.8); }
  body.dark .mfp-bg {
    background: rgba(20, 20, 22, 0.9); }

.mfp-zoom-in .popup {
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transform: scale(0.8); }

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  transition: all 0.2s ease-out; }

.mfp-zoom-in.mfp-ready .popup {
  opacity: 1;
  transform: scale(1); }

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-zoom-in.mfp-removing .popup {
  transform: scale(0.8);
  opacity: 0; }

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0; }

.trainer__avatar {
  width: 128px;
  height: 128px;
  margin: 0 auto 20px;
  border-radius: 50%;
  background: #E6E8EC;
  overflow: hidden; }
  body.dark .trainer__avatar {
    background: #353945; }
  .trainer__avatar img {
    width: 100%;
    min-height: 100%; }

.trainer__name {
  text-align: center; }

.trainer__position {
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 1.71429;
  color: #777E90; }

.trainer__socials {
  display: flex;
  justify-content: center;
  margin-bottom: 48px; }
  @media only screen and (max-width: 767px) {
    .trainer__socials {
      margin-bottom: 24px; } }

.trainer__social {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 24px;
  height: 24px; }
  .trainer__social .icon {
    width: 20px;
    height: 20px;
    fill: #777E90;
    transition: fill .2s; }
    .trainer__social .icon-twitter {
      width: 22px;
      height: 22px; }
  .trainer__social:hover .icon {
    fill: #23262F; }
    body.dark .trainer__social:hover .icon {
      fill: #E6E8EC; }
  .trainer__social:not(:last-child) {
    margin-right: 24px; }

.trainer .subscription {
  max-width: 300px;
  margin: 0 auto 48px; }
  @media only screen and (max-width: 767px) {
    .trainer .subscription {
      max-width: 100%;
      margin: 0; } }

.trainer__wrap {
  margin: 0 -16px; }
  @media only screen and (max-width: 767px) {
    .trainer__wrap {
      display: none; } }

.trainer__slider {
  padding-bottom: 50px;
  visibility: hidden; }
  .trainer__slider.slick-initialized {
    visibility: visible; }

.trainer__item {
  color: #23262F; }
  body.dark .trainer__item {
    color: #FCFCFD; }
  .trainer__item:hover .trainer__preview {
    box-shadow: 0 0 0 2px #FF592C; }
    .trainer__item:hover .trainer__preview img {
      transform: scale(1.1); }

.trainer__preview {
  position: relative;
  overflow: hidden;
  border-radius: 16px;
  transition: box-shadow .2s; }
  .trainer__preview img {
    width: 100%;
    transition: transform 2s; }

.trainer__category {
  position: absolute;
  top: 8px;
  left: 8px; }

.trainer__head {
  display: flex;
  align-items: flex-start;
  padding: 20px 0 12px; }

.trainer__level {
  flex-shrink: 0;
  margin-left: 10px; }

.trainer__title {
  margin-bottom: 2px;
  font-weight: 600; }

.trainer__parameters {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  border-top: 1px solid #E6E8EC; }
  body.dark .trainer__parameters {
    border-color: #353945; }

.trainer__parameter {
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 1.66667;
  font-weight: 600;
  color: #353945; }
  body.dark .trainer__parameter {
    color: #E6E8EC; }
  .trainer__parameter .icon {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    fill: #777E90; }

.trainer__btns {
  text-align: right; }
  @media only screen and (max-width: 767px) {
    .trainer__btns {
      display: none; } }

.trainer .slick-list {
  padding-top: 3px; }

.trainer .slick-slide {
  padding: 0 16px;
  opacity: 0;
  transition: opacity .4s; }
  .trainer .slick-slide.slick-active {
    opacity: 1; }

.trainer .slick-arrow {
  bottom: -32px; }

.trainer .slick-prev {
  left: 16px; }

.trainer .slick-next {
  left: 60px; }
