.programs {
    position: relative;
    overflow: hidden;
    &__anchor {
        position: absolute;
        top: -64px;
        left: 0;
        right: 0;
        @include d {
            top: -40px; } }
    &__top {
        max-width: 620px;
        margin: 0 auto 80px;
        text-align: center;
        @include d {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 40px; } }
    &__title {
        margin-bottom: 16px; }
    &__info {
        margin-bottom: 40px;
        @include caption-1;
        color: $neutrals4;
        strong {
            font-weight: 600; } }
    &__green {
        color: $green; }
    &__black {
        color: #23262F;
        @include dark {
            color: $neutrals8; } }
    &__wrap {
        margin: 0 -12px; }
    &__slider {
        padding-bottom: 104px;
        visibility: hidden;
        @include d {
            padding-bottom: 88px; }
        @include m {
            padding-bottom: 64px; }
        &.slick-initialized {
            visibility: visible; } }
    &__item {
        height: 100%;
        padding: 64px 32px;
        background: $neutrals7;
        border-radius: 20px;
        transition: all .2s;
        @include d {
            padding: 48px 24px; }
        @include m {
            padding: 64px 24px; }
        &:hover {
            box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.1);
            background: $neutrals8;
            .programs__button {
                background: $red;
                box-shadow: 0 0 0 2px $red inset;
                color: $neutrals8;
                &:hover {
                    background: darken($red, 10);
                    box-shadow: 0 0 0 2px darken($red, 10) inset;
                    color: $neutrals8; } } }
        @include dark {
            background: $neutrals2;
            &:hover {
                background: transparent;
                box-shadow: inset 0 0 0 1px $neutrals2; } } }
    &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 48px;
        height: 48px;
        margin-bottom: 32px;
        border-radius: 50%; }
    &__subtitle {
        margin-bottom: 16px;
        @include body-2; }
    &__content {
        margin-bottom: 32px;
        @include caption-1;
        color: $neutrals4; }
    .slick-track {
        display: flex; }
    .slick-slide {
        height: auto;
        padding: 0 12px; }
    .slick-arrow {
        bottom: 0; }
    .slick-prev {
        left: calc(50% - 34px); }
    .slick-next {
        right: calc(50% - 34px); } }
