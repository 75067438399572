.main {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 880px;
    padding: 34px 0 130px;
    @include x {
        min-height: 800px; }
    @include d {
        min-height: 624px; }
    @include m {
        min-height: auto;
        padding: 64px 0 152px; }
    &__wrap {
        position: relative;
        z-index: 3;
        max-width: 450px;
        @include t {
            max-width: 340px; }
        @include m {
            max-width: 100%;
            margin-bottom: 80px; } }
    &__title {
        margin-bottom: 20px; }
    &__text {
        margin-bottom: 40px;
        color: $neutrals4; }
    &__btns {
        display: flex;
        @include m {
            display: block; } }
    &__button {
        @include m {
            width: 100%; }
        &:not(:last-child) {
            margin-right: 16px;
            @include m {
                margin: 0 0 16px; } } }
    .scroll {
        position: absolute;
        bottom: 96px;
        @include d {
            bottom: 48px; }
        @include m {
            bottom: 64px; } }
    &__gallery {
        position: absolute;
        top: 50%;
        right: calc(50% - 465px);
        width: 512px;
        transform: translateY(-58%);
        pointer-events: none;
        @include x {
            right: calc(50% - 425px);
            width: 450px; }
        @include d {
            right: calc(50% - 365px);
            width: 320px; }
        @include t {
            right: calc(50% - 300px); }
        @include m {
            position: relative;
            top: auto;
            right: auto;
            left: -16px;
            margin: 0 auto;
            transform: translate(0,0);
            pointer-events: all; } }
    &__preview {
        &:first-child {
            position: relative;
            z-index: 2; }
        &:nth-child(2) {
            top: 36%;
            right: -18%;
            z-index: 3;
            width: 45%; }
        &:nth-child(3) {
            top: -12%;
            right: -7%;
            z-index: 1;
            width: 45%; }
        &:nth-child(4) {
            left: 11%;
            bottom: 13%;
            z-index: 3;
            width: 18%; }
        &:not(:first-child) {
            position: absolute; }
        img {
            width: 100%; } } }
