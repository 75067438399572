.trainers {
    &__title {
        margin-bottom: 20px;
        text-align: center; }
    &__info {
        max-width: 445px;
        margin: 0 auto 40px;
        text-align: center;
        color: $neutrals3;
        @include dark {
            color: $neutrals6; } }
    &__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: -12px -6px 80px;
        @include d {
            margin-bottom: 64px; }
        @include m {
            display: none; } }
    &__link {
        margin: 12px 6px 0;
        padding: 6px 12px;
        border-radius: 14px;
        background: none;
        @include button-2;
        color: $neutrals4;
        transition: all .2s;
        &:hover {
            color: $neutrals3;
            @include dark {
                color: $neutrals6; } }
        &.active {
            background: $neutrals3;
            color: $neutrals8;
            @include dark {
                background: $neutrals2;
                color: $neutrals8; } } }
    .select {
        display: none;
        @include m {
            display: block;
            margin-bottom: 48px; } }
    &__box {
        display: none;
        &:first-child {
            display: block; } }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -32px -16px 0;
        @include m {
            margin: -24px -8px 0; } }
    &__item {
        flex: 0 0 calc(25% - 32px);
        width: calc(25% - 32px);
        margin: 32px 16px 0;
        @include t {
            flex: 0 0 calc(33.333% - 32px);
            width: calc(33.333% - 32px); }
        @include m {
            flex: 0 0 calc(50% - 16px);
            width: calc(50% - 16px);
            margin: 24px 8px 0; } }
    &__element {
        position: relative;
        display: block;
        padding: 48px 32px;
        box-shadow: inset 0 0 0 1px $neutrals6;
        border-radius: 24px;
        text-align: center;
        color: $neutrals2;
        transition: box-shadow .2s;
        -webkit-tap-highlight-color: rgba(0,0,0,0);
        @include d {
            padding: 48px 16px; }
        @include m {
            padding: 24px 12px; }
        @include dark {
            box-shadow: inset 0 0 0 1px $neutrals3;
            background: $neutrals2;
            color: $neutrals8; }
        &:hover {
            box-shadow: inset 0 0 0 2px $neutrals5;
            @include dark {
                box-shadow: inset 0 0 0 2px $neutrals4; } } }
    &__avatar {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 128px;
        height: 128px;
        margin: 0 auto 32px;
        border-radius: 50%;
        overflow: hidden;
        background: $neutrals6;
        @include m {
            width: 80px;
            height: 80px;
            margin-bottom: 16px; }
        @include dark {
            background: $neutrals3; }
        img {
            width: 100%;
            min-height: 100%; } }
    &__name {
        margin-bottom: 4px;
        font-weight: 500;
        @include m {
            font-size: 12px; } }
    &__position {
        @include caption-1;
        color: $neutrals4;
        @include m {
            font-size: 12px; } } }
