.catalog {
    &__head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        @include m {
            display: block;
            margin-bottom: 20px; } }
    &__title {
        padding-top: 8px;
        @include body-1;
        @include m {
            display: none; } }
    &__search {
        position: relative;
        flex-shrink: 0;
        width: 320px;
        margin-left: 30px;
        @include t {
            width: 260px; }
        @include m {
            width: 100%;
            margin: 0; } }
    &__input {
        width: 100%;
        height: 48px;
        padding: 0 48px 0 14px;
        border-radius: 24px;
        background: none;
        border: 2px solid $neutrals6;
        @include poppins;
        @include caption-1;
        color: $neutrals2;
        transition: border-color .2s;
        @include dark {
            border-color: $neutrals3;
            color: $neutrals8; }
        @include placeholder {
            color: $neutrals4; }
        &:focus {
            border-color: $neutrals4; } }
    &__result {
        position: absolute;
        top: 8px;
        right: 8px;
        bottom: 8px;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $red;
        transition: background .2s;
        .icon {
            width: 16px;
            height: 16px;
            fill: $neutrals8; }
        &:hover {
            background: darken($red, 10); } }
    &__wrap {
        margin-bottom: 80px;
        padding-top: 32px;
        border-top: 1px solid $neutrals6;
        @include t {
            margin-bottom: 64px; }
        @include m {
            margin-bottom: 32px;
            padding-top: 0;
            border: none; }
        @include dark {
            border-color: $neutrals3; } }
    &__top {
        position: relative;
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid $neutrals6;
        @include m {
            display: block;
            margin-bottom: 20px;
            padding-bottom: 0;
            border: none; }
        @include dark {
            border-color: $neutrals3; }
        .select {
            width: 180px;
            @include t {
                width: 260px; }
            @include m {
                width: 100%;
                margin-bottom: 20px; } } }
    &__nav {
        position: absolute;
        top: 10px;
        left: 50%;
        display: flex;
        justify-content: center;
        transform: translateX(-50%);
        @include t {
            display: none; } }
    &__link {
        margin: 0 6px;
        padding: 6px 12px;
        border-radius: 14px;
        background: none;
        @include button-2;
        color: $neutrals4;
        transition: all .2s;
        &:hover {
            color: $neutrals3;
            @include dark {
                color: $neutrals6; } }
        &.active {
            background: $neutrals3;
            color: $neutrals8;
            @include dark {
                background: $neutrals8;
                color: $neutrals2; } } }
    &__filter {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 0 0 0 24px;
        flex-shrink: 0;
        border-radius: 24px;
        background: $red;
        @include sf-pro-display;
        font-size: 16px;
        line-height: 48px;
        color: $neutrals8; }
    &__close {
        width: 52px;
        padding-right: 10px;
        background: none;
        font-size: 0;
        .icon {
            width: 10px;
            height: 10px;
            fill: $neutrals8;
            transition: transform .2s; }
        &:hover {
            .icon {
                transform: rotate(90deg); } } }
    &__sorting {
        display: flex;
        flex-wrap: wrap;
        margin: -32px -16px 0;
        @include d {
            margin: -24px -8px 0; }
        @include t {
            margin: -32px -16px 0; }
        @include m {
            display: block;
            margin: 0; } }
    &__cell {
        flex: 0 0 calc(25% - 32px);
        max-width: calc(25% - 32px);
        margin: 32px 16px 0;
        @include d {
            flex: 0 0 calc(25% - 16px);
            max-width: calc(25% - 16px);
            margin: 24px 8px 0; }
        @include t {
            flex: 0 0 calc(50% - 32px);
            max-width: calc(50% - 32px);
            margin: 32px 16px 0; }
        @include m {
            max-width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 20px; } } }
    &__label {
        margin-bottom: 12px;
        @include hairline-2;
        color: $neutrals5; }
    &__list {
        display: flex;
        flex-wrap: wrap;
        margin: -32px -16px 0;
        @include m {
            display: block;
            margin: 0; } }
    .card {
        flex: 0 0 calc(33.333% - 32px);
        max-width: calc(33.333% - 32px);
        margin: 32px 16px 0;
        @include r(1260) {
            flex: 0 0 calc(50% - 32px);
            max-width: calc(50% - 32px); }
        @include m {
            max-width: 100%;
            margin: 0;
            &:not(:last-child) {
                margin-bottom: 32px; } } }
    &__btns {
        margin-top: 80px;
        text-align: center;
        @include d {
            margin-top: 64px; }
        @include m {
            margin-top: 32px; } } }
