.intro {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 816px;
    padding: 100px 0;
    @include d {
        min-height: 600px;
        padding: 80px 0; }
    @include m {
        display: block;
        min-height: auto;
        padding: 32px 0 64px; }
    &__wrap {
        position: relative;
        z-index: 3;
        max-width: 450px;
        @include t {
            max-width: 360px; } }
    &__title {
        margin-bottom: 32px; }
    &__text {
        margin-bottom: 40px;
        @include body-1;
        color: $neutrals4; }
    &__gallery {
        position: absolute;
        top: 53%;
        right: calc(50% - 541px);
        width: 584px;
        transform: translateY(-40%);
        pointer-events: none;
        @include d {
            right: calc(50% - 440px);
            width: 390px;
            transform: translateY(-44%); }
        @include t {
            right: calc(50% - 380px); }
        @include m {
            position: relative;
            top: auto;
            right: auto;
            left: -15px;
            margin: 96px auto 24px;
            transform: translateY(0); } }
    &__preview {
        &:first-child {
            position: relative;
            z-index: 2; }
        &:nth-child(2) {
            top: -27%;
            left: 4%;
            z-index: 1;
            width: 33%; }
        &:nth-child(3) {
            top: -18%;
            right: 31%;
            z-index: 1;
            width: 33%; }
        &:not(:first-child) {
            position: absolute; }
        img {
            width: 100%; } } }
